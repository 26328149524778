<template>
  <div
    class="container"
    ref="container"
    :class="{ 'small-screen': smallScreen, 'is-ios': isiOS }"
  >
    <div class="__banner-warp">
      <img @click="toIntro" src="~@/assets/images/banner.png" alt="" />
      <div class="__line"></div>
    </div>
    <div class="__content" :class="{ 'no-bottom': !bottomShow }">
      <div class="__tab-warp">
        <div
          class="__tab-btn"
          :class="{ active: activeTab === 'shu' }"
          @click="changeTab('shu')"
        >
          竖屏
        </div>
        <div
          class="__tab-btn"
          :class="{ active: activeTab === 'heng' }"
          @click="changeTab('heng')"
        >
          横屏
        </div>
      </div>
      <!-- 模板分类 -->
      <div
        class="__template-type-btn-warp"
        :style="{ height: (openMenu ? menuMaxHeight : menuHeight) + 'px' }"
      >
        <div
          class="__arrow-warp"
          @click="openMenu = !openMenu"
          v-show="template_types.length > 4"
        >
          <div class="__arrow" :class="{ open: openMenu }"></div>
        </div>
        <div
          v-for="item in template_types"
          class="__template-type-btn"
          :class="{ active: activeType === item.id }"
          :key="item.id"
          @click="changeTemplateType(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- 模板分类 END -->
      <div class="__video-warp" ref="videoWarp" v-if="activeTab === 'heng'">
        <XGvideoPlayer
          v-for="item in videoHeng"
          :key="item.url"
          v-bind="item"
        />
      </div>

      <div
        class="__video-warp __shu"
        ref="videoWarp"
        v-if="activeTab === 'shu'"
      >
        <XGvideoPlayer
          style="width: calc(50% - 0.05rem)"
          v-for="item in videoShu"
          :key="item.url + item.name"
          v-bind="item"
        />
      </div>
      <div class="__line-text">
        <div class="__small-line"></div>
        <div class="__text">更多内容请前往电脑端</div>
        <div class="__small-line"></div>
      </div>
      <div class="__more">
        <div class="__top">
          <div class="__text-warp">
            <img
              class="__huoqugengduo"
              src="~@/assets/images/zhichipiliang.png"
              alt=""
            />
            <div class="__text-warp-text">扫描二维码添加微信</div>
          </div>
          <div class="__ewm-warp">
            <img class="__ewm" src="~@/assets/images/ewm.png" alt="" />
          </div>
        </div>
        <div class="__bottom-more">
          <van-button class="__btn" type="info" @click="makeCall"
            >拨打电话</van-button
          >
          <van-button class="__btn __cooperation" @click="toCooperation"
            >合作留言</van-button
          >
        </div>
      </div>
    </div>
    <div class="__bottom" v-show="bottomShow">
      <div class="__message-box">
        <div class="__message-box-w">
          <div class="__logo">
            <img src="~@/assets/images/logo.png" alt="" />
          </div>
          <div class="__text-warp">
            <div class="__text-1">https://www.d-human.cn</div>
            <div class="__text-2">请前往电脑端体验完整功能</div>
          </div>
        </div>
        <div
          class="__bottom-btn"
          v-clipboard:copy="'https://www.d-human.cn'"
          v-clipboard:success="onCopy"
        >
          点击复制
        </div>
        <div class="__close" @click="clickClose">
          <img src="~@/assets/images/close.png" alt="" />
        </div>
      </div>
    </div>
    <div class="__toast-mask" v-show="showToast" @click="showToast = false">
      <div class="__message">
        <div>复制成功</div>
      </div>
    </div>
  </div>
</template>

<script>
import Player from 'xgplayer/dist/simple_player';
import volume from 'xgplayer/dist/controls/volume';
import playbackRate from 'xgplayer/dist/controls/playbackRate';
import XGvideoPlayer from '@/components/XGvideoPlayer.vue';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
const _16VS9 = '16:9';
const _9VS16 = '9:16';
export default {
  name: 'HomeView',
  components: {
    XGvideoPlayer,
  },
  data() {
    return {
      bottomShow: true,
      smallScreen: false,
      loading: false,
      isiOS: false,
      activeTab: 'shu',
      template_types: [],
      videoHeng: [],
      videoShu: [
        
      ],
      showURL: '/video/video_heng_1.mp4',
      videoMask: true,
      player: null,
      width: 0,
      showToast: false,
      openMenu: false,
      menuHeight: 46,
      menuMaxHeight: 46,
      activeType: -1,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.androidIos();
    });
    this.getTemplateTypeList(_9VS16);
  },
  methods: {
    toIntro(){
      this.$router.push({
        path: '/dhuman_intro'
      })
    },
    // 获取模板类型列表
    getTemplateTypeList(size) {
      const params = {
        is_show_empty: 0,
        canvas_size: size,
      };
      axios
        .request({
          url: '/v1.0/virtual-human/template-category',
          method: 'get',
          params,
        })
        .then((res) => {
          const data = res.data.data.template_category;
          this.template_types = data.data;
          const menuMaxHeight = Math.ceil(this.template_types.length / 4) * 46;
          this.menuMaxHeight = menuMaxHeight;
          if (this.menuMaxHeight === this.menuHeight) {
            this.openMenu = false;
          }
          this.changeTemplateType(this.template_types[0]);
        });
    },
    onCopy() {
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 1000);
    },
    clickClose() {
      this.bottomShow = false;
    },
    initPlayer({ align, url, poster }) {
      this.showURL = url;
      const width = this.$refs.videoWarp.clientWidth;
      let height = 0;
      if (align === 'heng') {
        height = (width / 16) * 9;
      }
      if (align === 'shu') {
        height = (width / 9) * 16;
      }
      if (this.player) {
        this.player.destroy(true);
        this.player = null;
      }
      this.player = new Player({
        id: 'player',
        url: url,
        controlPlugins: [volume, playbackRate],
        width,
        height,
        poster,
        playsinline: true,
        'x5-video-player-type': 'h5',
      });
    },
    androidIos() {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isiOS) {
        this.isiOS = true;
      }
    },
    changeTab(tab) {
      this.activeTab = tab;
      if (tab === 'heng') {
        this.getTemplateTypeList(_16VS9);
      }
      if (tab === 'shu') {
        this.getTemplateTypeList(_9VS16);
      }
    },
    changeTemplateType(type) {
      this.activeType = type.id;
      this.getTypeTemplateList(type.id);
    },
    // 获取类型下的所有模板
    getTypeTemplateList(template_category_id) {
      const params = {
        per_page: 1000,
        canvas_size: this.activeTab === 'shu' ? _9VS16 : _16VS9,
        template_category_id,
        current_page: 1,
      };
      axios
        .request({
          url: '/v1.0/virtual-human/work-template',
          method: 'get',
          params,
        })
        .then((res) => {
          const data = res.data.data;
          const work_template = data.work_template.data;
          const videoShu = [];
          const videoHeng = [];
          work_template.forEach((item, index) => {
            const video = {
              name: item.name,
              url: item.video_url || Math.random() + '',
              poster: item.preview,
              align: this.activeTab,
              videoId: uuidv4()
            };
            if (this.activeTab === 'shu') {
              videoShu.push(video);
            }
            if (this.activeTab === 'heng') {
              videoHeng.push(video)
            }
          });
          if (this.activeTab === 'shu') {
            this.videoShu = videoShu;
            this.initPlayer(this.videoShu[0]);
          }
          if (this.activeTab === 'heng') {
            this.videoHeng = videoHeng;
            this.initPlayer(this.videoHeng[0]);
          }
        });
    },
    changeVideo(item) {
      this.initPlayer(item);
    },
    videoPlay() {
      this.$refs.video.play();
      this.videoMask = false;
    },
    makeCall() {
      const phone = '17607121873';
      window.location.href = 'tel:' + phone;
    },
    toCooperation() {
      this.$router.push('/cooperation');
    },
  },
};
</script>

<style lang="stylus" scoped>
.__template-type-btn-warp
  padding-top 20px
  padding-left 20px
  height 46px
  overflow-y hidden
  position relative
  transition .35s
  .__template-type-btn
    width: calc(25% - 14px);
    height: 26px;
    background: #F2F6F7;
    border-radius: 13px 13px 13px 13px;
    line-height 26px
    display inline-block
    margin-right 12px
    margin-bottom 16px
    text-align center
    &.active
      background: #E8F2FF;
      color: #177FFF;
  .__arrow-warp
    width 26px
    height 26px
    top: 20px;
    right 10px;
    position absolute
    background-color #ffffff
    .__arrow
      width 26px
      height 26px
      background-image url('@/assets/images/arrow.png')
      background-size 16px 16px;
      background-repeat no-repeat
      background-position center center
      transform rotateZ(180deg)
      transition .35s
      &.open
        transform rotateZ(0deg)
.container
  max-width: 600px;
  position: relative;
  margin: 0 auto;
  width 100vw
  &.is-ios
    .notice-warp
      height: 31px;
      height: calc(31px + constant(safe-area-inset-bottom));
      height: calc(31px + env(safe-area-inset-bottom));
  .__banner-warp
    font-size 0px
    padding .08rem .16rem 0 .16rem
    background-color #fff
    img
      max-width 100%
      width 100%
  .__line
    height 8px
    background-color #fff
  .__content
    background-color #ffffff
    padding-bottom 1.02rem
    &.no-bottom
      padding-bottom .2rem
    .__tab-warp
      display flex
      height .44rem
      box-shadow: 0px 4px 4px 0px rgba(23,127,255,0.1);
      .__tab-btn
        width 50%
        font-size .16rem
        line-height .44rem
        text-align center
        position relative
        color #666
        font-family: PingFang SC-Medium, PingFang SC;
        &.active
          color #000
          font-weight bold
          &:after
            content ''
            position absolute
            width: .16rem
            height: 2px;
            background: #177FFF;
            border-radius: 4px 4px 4px 4px
            left 50%
            bottom .06rem
            transform translateX(-0.08rem)
    .__btn-warp
      display flex
      justify-content space-between
      padding 0 .1rem
      margin .12rem 0
      .__btn
        padding .03rem .12rem
        height: .26rem;
        min-width .74rem
        background: #F2F6F7;
        border-radius: 13px 13px 13px 13px;
        opacity: 1
        display flex
        align-items center
        justify-content center
        .__btn-text
          height: .2rem;
          font-size: .14rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: .2rem
          user-select none
        &.active
          background: #E8F2FF;
          .__btn-text
            color #177fff
    .__video-warp
      position relative
      margin 0rem .16rem .0rem .16rem
      padding-top .16rem
      &.__shu
        display flex
        flex-wrap wrap
        justify-content space-between
      #player
        border-radius: 4px;
        overflow: hidden;
      .__video-item
        max-width 100%
        width 100%
        border-radius 4px
      .__video-mask
        position absolute
        z-index 1
        width 100%
        height 100%
        top: 0
        left 0
        border-radius 4px
        background-color: rgba(0,0,0,0.05)
        background-image url('~@/assets/images/icon_play.png')
        background-size .52rem .52rem
        background-repeat no-repeat
        background-position center center
  .__bottom
    position fixed
    bottom 0
    left 0
    z-index 1001
    width: 100%;
    height: .96rem;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #FFFFFF 100%)
    pointer-events none
    padding .1rem .16rem .24rem .16rem
    display flex
    justify-content center
    .__message-box
      position relative
      height: .62rem;
      background: #FFFFFF;
      box-shadow: 0px 4px 12px 0px rgba(23,127,255,0.2);
      border-radius: 4px 4px 4px 4px
      display flex
      align-items center
      padding 0 .28rem 0 .12rem
      justify-content space-between
      max-width: 600px;
      width: 100%;
      .__message-box-w
        display flex
        align-items center
      .__logo
        width .38rem
        height .38rem
        img
          width 100%
          height 100%
      .__text-warp
        margin-left .08rem
        .__text-1
          font-size: .12rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #666666;
        .__text-2
          font-size: .12rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333
      .__bottom-btn
        margin-left .22rem
        width: .8rem;
        height: .32rem;
        line-height .32rem;
        text-align center
        background: #177FFF;
        font-weight: 500;
        font-family: PingFang SC-Medium, PingFang SC;
        color: #FFFFFF;
        border-radius: 16px 16px 16px 16px
        pointer-events all
      .__close
        position absolute
        width: .24rem
        height: .24rem
        background: #C3C3CD;
        border-radius: 0px .16rem 0px .8rem
        right 0
        top 0
        pointer-events all
        img
          width .16rem
          margin .02rem 0 0 .06rem
  .__toast-mask
    position fixed
    width 100%
    height 100%
    z-index 10001
    background-color rgba(0, 0, 0, 0)
    top 0
    left 0
    display flex
    align-items center
    justify-content center
    .__message
      color #fff
      background-color rgba(0, 0, 0, .85)
      width 1rem
      height 1rem
      display flex
      align-items center
      justify-content center
      font-size .16rem
      border-radius .05rem
  .__line-text
    display flex
    align-items center
    justify-content center
    .__small-line
      width: .16rem;
      height: 1px;
      background: #B6C3CC
      margin .08rem
    .__text
      height: 17px;
      font-size: 12px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #B6C3CC;
      line-height: 14px
  .__more
    background: #FFFFFF;
    box-shadow: 0px 4px 12px 0px rgba(3,3,3,0.1);
    border-radius: 4px 4px 4px 4px
    margin .16rem .16rem
    padding .16rem .25rem
    .__top
      display flex
      justify-content space-between
      .__text-warp
        display flex
        flex-direction column
        .__text-warp-text
          height: 20px;
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #177FFF;
          line-height: 20px;
          margin-top .04rem
          letter-spacing: 2px;
        .__huoqugengduo
          height .50rem
      .__ewm-warp
        width: .8rem;
        height: .8rem;
        background: rgba(153,153,153,0.12);
        border-radius: 4px 4px 4px 4px;
        padding .06rem
        .__ewm
          width .68rem
          height .68rem
    .__bottom-more
      display flex
      justify-content space-between
      margin-top .17rem
      .__btn
        height .36rem
        background: #E8F2FF
        color #177fff
        border none
        width calc(50% - .08rem)
        &.__cooperation
          background: #177FFF
          color #fff
</style>
