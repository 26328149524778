import axios from "axios";

const baseURL =  process.env.NODE_ENV == 'development' ? '/business_contact'  : process.env.VUE_APP_BUSINESSURL
const sendUrl =  process.env.NODE_ENV == 'development' ? '/sendMail_contact' : process.env.VUE_APP_SENDMAIL 

export function getRightContact() {
    return axios.request({
        url:baseURL + `/business/rightcontact/queryContactInfo?address=d-human.cn`,
        headers:{
          Rsa_token:"Rvj1m9qCUP4sNMpcTeBlikbtQWBV2ZMj5rJovw02e2JnrcUiy1x54/VV9mj/vOBsAn9IWWaTCXPrN7aPvgLmzTLf9WJ5zHVjBwBC3naGNBdYn5gO1BFRUNP2rvdCf5H8GPcjiDzHccS/vk+5SySyWOTggH+V7A62l+xriDN1hmvoLDVXe2hwe49yY3Cd2X5g8F25xfVVpY29c5oBB4oPlSmMCNvX5ZsFwMR0neKKBJ4Ez3BvRDzFeSiLjW23RvPgPV/0mxdtRdADBzgaMkPQfRC0N5FxP07s7y11fDNjLI0M4asFBtqGEalmKqMwAtZXmhvIzHjRO4CgkYgTRK5wPA==",
          Sign_data:"55f40870-4155-4d88-b272-589638b843c3"
        },
        method:'get'
    })
}


export function sendEmail(data) {
  return axios.request({
      url:sendUrl + `/admin/email/sendEmail`,
      headers:{
        rsa_token:"eJK8fk06qFqwYuMP0FgVAb/sMX7p9D0YA7dT887J1Dt37vcQssE1jUBl/O4RGorLfJCM7ebcIchNmOFcaVDq8ge6XwLPFzeHy5mCuPWFKFjckayHNM5WBaq/o7LNq0+7hYyCiCjIFOK8WFX2Y/D9PHgAJK060Ihh1rZlbgWvIn5xqOrpyEVM9MH1MQDh8YcNjyztHOD8lfeLLmJetoHFKSXARYtiXL3K//OCEJu7haujfR/1UWqmez3NTvFVJhmPDozlOw9E+ja0CgC3xIEM+N6n69xXAY/VAyoOHnmU8/4bZliBa6PKcJ9bLtkd1nCKXjE9gXVp0QlVg7Ve75mxaw==",
        sign_data:"AAAABBBBCCCC"
      },
      method:'post',
      data
  })
}