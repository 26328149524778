import { mapGetters } from 'vuex'
import { getRightContact } from "@/api/business.js"
export default {
  data(){
    return {
        contactData:[],
        contactIndex:0
    }
  },
  mounted(){
      this.getContact()
  },
  computed: {
    // ...mapGetters(['brand']),
    oem_phones(){
      let phones = []
      // const brand_contact = this.brand.brand_contact;
      // if(brand_contact && brand_contact.length){
      //   brand_contact.forEach((item, index) => {
      //     if(item.page.includes(5)){
      //       if(item.phone){
      //         phones.push(item.phone)
      //       }
      //     }
      //   });
      // }
      getRightContact().then(res => {
        let contactData = res.data.data.contactList
        contactData.forEach((item, index) => {
          phones.push(item.mobile)
        });
      })
      return phones
    },
    oem_wx_qrcode_urls(){
      let wx_qrcode_urls = []
      // const brand_contact = this.brand.brand_contact;
      // if(brand_contact && brand_contact.length){
      //   brand_contact.forEach((item, index) => {
      //     if(item.page.includes(5)){
      //       if(item.wx_qrcode_url){
      //         wx_qrcode_urls.push(item.wx_qrcode_url)
      //       }
      //     }
      //   });
      // }
      getRightContact().then(res => {
        let contactData = res.data.data.contactList
        contactData.forEach((item, index) => {
          wx_qrcode_urls.push(item.wechatQrCode)
        });
      })
      return wx_qrcode_urls
    }
  },
  methods:{
    getContact(){
      getRightContact().then(res => {
          this.contactData = res.data.data
          this.contactIndex = Math.floor(Math.random() * this.contactData.contactList.length)
      })
  },
  }
}