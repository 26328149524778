<template>
  <div
    class="__phone-call-mask"
    v-show="show"
    @click="close"
    :class="{ '__show-panel': showPanel }"
  >
    <div
      class="__panel"
      @click.stop="() => {}"
      :style="{ marginBottom: showPanel ? '0' : '-100%' }"
    >
      <div class="__phone-warp">
        <div
          @click="callItem(item)"
          class="__phone-item"
          v-for="(item, index) in oem_phones"
          :key="`${item}_${index}`"
        >
          {{ item }}
        </div>
      </div>
      <div class="__line"></div>
      <div class="__cancel" @click="close">取消</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OemContact from '@/mixins/OemContact';
export default {
  mixins: [OemContact],
  data() {
    return {
      show: false,
      showPanel: false,
    };
  },
  computed: {
    ...mapGetters(['brand']),
  },
  mounted() {},
  methods: {
    call() {
      const oem_phones = this.oem_phones;
      console.log(oem_phones)
      if (oem_phones.length === 1) {
        const phone = oem_phones[0];
        window.location.href = 'tel:' + phone;
      } else {
        this.show = true;
        setTimeout(() => {
          this.showPanel = true;
        }, 10);
      }
    },
    callItem(phone) {
      window.location.href = 'tel:' + phone;
    },
    close() {
      this.showPanel = false;
      setTimeout(() => {
        this.show = false;
      }, 300);
    },
  },
};
</script>

<style scoped lang="stylus">
.__phone-call-mask
  position fixed
  width 100vw
  height 100vh
  background-color rgba(0, 0, 0, 0)
  top 0
  left 0
  z-index 100001
  transition .3s
  &.__show-panel
    background-color rgba(0, 0, 0, .5)
  .__panel
    background-color #fff
    border-radius .2rem .2rem 0 0
    width 100%
    position absolute
    bottom 0
    transition .35s
    margin-bottom -100%
    .__phone-warp
      .__phone-item
        line-height 0.56rem
        text-align center
        font-size 0.17rem
        color #333
        color: #177FFF
        border-bottom 1px solid rgba(0, 0, 0 , 0.1)
        &:last-child
          border none
    .__line
      width: 100%;
      height: 8px;
      background: #F2F2F2
  .__cancel
    height: .56rem
    background: #FFFFFF;
    line-height .56rem
    text-align center
    font-size: 17px;
    font-weight: 500;
    color: #000000;
    box-sizing content-box
    padding-bottom: constant( safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom)
</style>
