<template>
  <div class="__activity">
    <h1 class="__page-title-h1">请填写您的申请信息</h1>
    <h2 class="__page-title-h2">我们的工作人员将尽快与您联系确定申请资格</h2>
    <van-radio-group v-model="formData.type" @change="handleTypeChange">
      <div class="__type">
        <van-radio class="__radio" name="0">个人</van-radio>
        <van-radio class="__radio" name="1">企业</van-radio>
      </div>
    </van-radio-group>

    <div class="__form">
      <div class="__field-container">
        <van-field v-model="formData.course_name" placeholder="课程名称" />
      </div>
      <div @click="handleClickDate" class="__field-container __date">
        {{ formData.time ? formData.time : '课程制作时间' }}
      </div>
      <van-popup v-model="popShow" position="bottom" :overlay="true">
        <van-datetime-picker
          v-model="formData.date"
          type="date"
          :min-date="minDate"
          @confirm="handleDateConfirm"
          @cancel="handleDateCancel"
        />
      </van-popup>

      <!-- 个人 -->
      <template v-if="formData.type === '0'">
        <div class="__field-container">
          <van-field v-model="formData.proposer" placeholder="申请人姓名" />
        </div>
        <div class="__field-container">
          <van-field v-model="formData.phone" placeholder="手机号码" />
        </div>
        <div class="__materials">
          <div>申请人在职证明</div>
          <van-uploader
            :after-read="handleOnRead"
            @oversize="handleOverSize"
            :max-size="1024 * 1024 * 5"
          >
            <van-button plain type="info" size="small">点击上传</van-button>
          </van-uploader>
          <a class="__a-download" :href="TEMPLATE_DOWNLOAD_URL_PERSONAL"
            >下载模板</a
          >
        </div>
        <div class="__template-image-warp">
          <img class="__image" :src="template_image" alt="" />
        </div>
      </template>
      <!-- 单位 -->
      <template v-if="formData.type === '1'">
        <div class="__field-container">
          <van-field v-model="formData.company" placeholder="单位名称" />
        </div>
        <div class="__field-container">
          <van-field
            v-model="formData.leader_name"
            placeholder="单位负责人姓名"
          />
        </div>
        <div class="__field-container">
          <van-field
            v-model="formData.leader_phone"
            placeholder=" 负责人手机号码"
          />
        </div>
        <div class="__materials">
          <div>学院/机构申请函</div>
          <van-uploader
            :after-read="handleOnRead"
            @oversize="handleOverSize"
            :max-size="1024  * 1"
          >
            <van-button plain type="info" size="small">点击上传</van-button>
          </van-uploader>
          <a class="__a-download" :href="TEMPLATE_DOWNLOAD_URL_COMPANY"
            >下载模板</a
          >
        </div>
        <div class="__template-image-warp">
          <img class="__image" :src="template_image" alt="" />
        </div>
      </template>

      <div class="__btn-warp">
        <van-button type="info" size="large">立即提交</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
const TEMPLATE_DOWNLOAD_URL_PERSONAL =
  'https://deepsound-tts-dev.oss-cn-shenzhen.aliyuncs.com/virtual_human_service/activity_202307/20230705%E6%B7%B1%E5%A3%B0%E7%A7%91%E6%8A%80%E4%BC%9A%E8%AE%AE%E7%BA%AA%E8%A6%81.docx';
const TEMPLATE_DOWNLOAD_URL_COMPANY =
  'https://deepsound-tts-dev.oss-cn-shenzhen.aliyuncs.com/virtual_human_service/activity_202307/20230705%E6%B7%B1%E5%A3%B0%E7%A7%91%E6%8A%80%E4%BC%9A%E8%AE%AE%E7%BA%AA%E8%A6%81.docx';
const TEMPLATE_IMAGE_PERSONAL = require('@/assets/images/1.20.0/template_personal.png');
const TEMPLATE_IMAGE_COMPANY = require('@/assets/images/1.20.0/template_personal.png');
export default {
  data() {
    return {
      TEMPLATE_DOWNLOAD_URL_PERSONAL,
      TEMPLATE_DOWNLOAD_URL_COMPANY,
      template_image: TEMPLATE_IMAGE_PERSONAL,
      minDate: new Date(),
      popShow: false,
      formData: {
        type: '0',
        course_name: '',
        time: '',
        proposer: '', // 申请人姓名
        phone: '',
        company: '',
        leader_name: '',
        leader_phone: '',
      },
    };
  },
  mounted() {},
  methods: {
    handleClickDate() {
      this.popShow = true;
    },
    handleDateConfirm(val) {
      const date = moment(val).format('YYYY年MM月DD日');
      this.formData.time = date;
      this.popShow = false;
    },
    handleDateCancel() {
      this.popShow = false;
    },
    handleOnRead(file) {
      console.log(file);
      this.template_image = file.content;
    },
    handleOverSize() {
      alert('超出限制大小');
    },
    handleTypeChange(type) {
      if (type === '0') {
        this.template_image = TEMPLATE_IMAGE_PERSONAL;
      }
      if (type === '1') {
        this.template_image = TEMPLATE_IMAGE_COMPANY;
      }
    },
  },
};
</script>

<style scoped lang="stylus">
.__activity
  position relative
  margin 0 auto
  max-width 600px
  padding .24rem
  .__type
    display flex
    .__radio
      &:last-child
        margin-left .12rem
  .__page-title-h1
    font-size .16rem
    font-weight normal
    margin-bottom .08rem
  .__page-title-h2
    font-size .16rem
    font-weight normal
    margin-bottom .12rem
  .__form
    margin-top .2rem
    .__field-container
      border 1px solid #ddd
      margin-bottom .12rem
      &.__date
        padding-left .12rem
        height .44rem
        line-height .44rem
        color #8f99ad
    .__materials
      display flex
      align-items center
      justify-content space-between
      .__a-download
        color #177fff
        text-decoration none
  .__template-image-warp
    margin-top .12rem
    .__image
      width 100%
      max-width 100%
  .__btn-warp
    margin-top .12rem
</style>
