<template>
  <div class="__verify-container" @click.stop="">
    <div class="__verify-header">
      <div class="_title">请完成安全验证</div>
      <van-icon name="cross" @click="closeSlideDialog" />
      <!-- <div class="deep-icon icon-guanbi1" @click="closeSlideDialog"></div> -->
    </div>
    <div class="component">
      <div class="__loading-mask" v-show="isLoading"></div>
      <div
        :class="[browserInfo.device === this.TARGET_DEVICE ? 'img-box' : 'img-box-mobile']"
        :style="{ width: VERIFY_WIDTH + 'px', height: VERIFY_HEIGHT + 'px' }"
      >
        <div
          :class="[
            browserInfo.device === this.TARGET_DEVICE ? 'btn-group' : 'btn-group-mobile',
          ]"
          
        >
          <van-icon name="revoke" @click="refresh" />
        </div>
        <div>
          <img
            :class="[browserInfo.device === this.TARGET_DEVICE ? 'img1' : 'img1-mobile']"
            :src="baseImg"
            alt=""
            ref="sliderBg"
          />
          <img
            class="img2"
            ref="sliderBlock"
            :src="puzzleImg"
            :style="{ top: top, left: left }"
            draggable="false"
          />
        </div>
        <div class="cover load-fail" v-if="loadFail">
          <div class="load-fail-img"></div>
          <div class="load-fail-text">图片加载失败，请点击刷新</div>
        </div>
        <div
          :class="{
            success: isSuccess,
            fail: !isSuccess,
            'tips-box': browserInfo.device === this.TARGET_DEVICE,
            'tips-box-mobile': browserInfo.device !== this.TARGET_DEVICE,
          }"
          v-show="puzzleTips"
        >
          <img
            v-show="isSuccess"
            src="@/assets/images/components/success.png"
            alt=""
          />
          <img
            v-show="!isSuccess"
            src="@/assets/images/components/fail.png"
            alt=""
          />
        </div>
      </div>
      <div
        :class="[
          browserInfo.device === this.TARGET_DEVICE ? 'slider' : 'slider-mobile',
          isDisable ? 'slider--disable' : '',
        ]"
        :style="{ width: VERIFY_WIDTH + 'px' }"
      >
        <div :class="[browserInfo.device === this.TARGET_DEVICE ? 'text' : 'text-mobile']">
          <div v-show="leftNumber < 4">拖动滑块完成填充拼图</div>
        </div>
        <div
          class="left-slider"
          :class="{
            'is-moving': leftNumber > 2,
            'is-success': isSuccess === true,
            'is-fail': isSuccess === false,
          }"
          v-show="leftNumber > 2"
          :style="{ width: `calc(${left} + 4px)` }"
        ></div>
        <div
          class="drag"
          :class="{
            'is-moving': leftNumber > 2,
            'is-success': isSuccess === true,
            'is-fail': isSuccess === false,
          }"
          ref="drag"
          :style="{ left: left }"
        >
          <div
            :class="[isSuccess === false ? 'icon-guanbi1' : 'icon-arrowRight']"
            class="deep-icon"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Drag from './drag.class.js';
import { getImgCaptcha, verifyCaptcha } from '@/api/index.js';
import * as api from '@/api'
let startTime = 0;
let captchaInfo = {};
const BEGIN_LEFT = 0;
const VERIFY_WIDTH = 320 * 0.75;
const VERIFY_HEIGHT = 180 * 0.75;

export default {
  props: {
    taskId: {
      type: String,
      default: '',
    },
    phone: String,
    areaCode: String,
    validateType: String
  },
  data() {
    return {
      TARGET_DEVICE: 'mobile',
      VERIFY_WIDTH,
      VERIFY_HEIGHT,
      baseImg: '',
      puzzleImg: '',
      isLoading: false,
      top: 0,
      left: 0,
      isDisable: true,
      captcha_id: 0,
      puzzleTips: '',
      isSuccess: null,
      refreshCount: 0,
      imgLoaded: [false, false],
      loadFail: false,
      browserInfo: {
        device: 'mobile',
      },
      dragbox: null,
      imgDragbox: null,
      verifying: false,
    };
  },
  computed: {
    leftNumber() {
      return this.left ? Number(this.left.split('px')[0]) : 0;
    },
  },
  mounted() {
    this.$refs.sliderBg.addEventListener('load', () => {
      this.onload('bg');
    });
    this.$refs.sliderBlock.addEventListener('load', () => {
      this.onload('block');
    });
    let box = this.$refs.drag;
    if (this.browserInfo.device === this.TARGET_DEVICE) {
      this.left = BEGIN_LEFT + 'px';
      this.dragbox = new Drag(
        box,
        BEGIN_LEFT,
        {
          xs: [0, VERIFY_WIDTH],
        },
        this.mousemove,
        this.mouseup,
      );
    }
  },
  destroyed() {
    clearTimeout(this.timer);
    if (this.$refs.sliderBg) {
      this.$refs.sliderBg.removeEventListener('onload', this.onload);
    }
    if (this.$refs.sliderBlock) {
      this.$refs.sliderBlock.removeEventListener('onload', this.onload);
    }
  },
  methods: {
    mousedown(e) {},
    getCaptcha() {},
    verifyCaptcha() {},
    mousemove(left) {
      this.left = left + 'px';
    },
    mouseup(left) {
      const style = this.$refs.drag.style;
      left = style.left.replace('px', '') * 1;
      this.verify(left);
    },
    mousemoveImg(left) {
      console.log(left);
      this.left = left + 'px';
    },
    mouseupImg(left) {
      const style = this.$refs.sliderBlock.style;
      left = style.left.replace('px', '') * 1;
      this.verify(left);
    },
    refresh() {
      startTime = Date.now();
      this.loadFail = false;
      this.isDisable = true;
      this.dragbox.initPosition();
      this.isLoading = true;
      this.imgLoaded = [false, false];
      if (this.browserInfo.device === this.TARGET_DEVICE) {
        this.left = BEGIN_LEFT + 'px';
      } else {
        this.left = 40 + 'px';
      }
      this.puzzleTips = '';
      this.getCaptchas().catch((err) => {
        this.captchaErr(err.type, err.obj);
      });
      let imgDrag = this.$refs.sliderBlock;
      this.imgDragbox = new Drag(
        imgDrag,
        BEGIN_LEFT,
        {
          xs: [0, VERIFY_WIDTH],
        },
        this.mousemoveImg,
        this.mouseupImg,
      );
      this.imgDragbox.initPosition();
    },
    setDisable(bool) {
      this.isDisable = bool;
    },
    verify(left) {
      if (this.verifying === true) {
        return;
      }
      this.verifying = true;
      let percentX =
        this.browserInfo.device === this.TARGET_DEVICE ? left / VERIFY_WIDTH : left / 640;
      this.isDisable = true;
      const params = {
        captcha_id: this.captcha_id,
        percent_x: percentX,
        captcha_type: 'sliding',
        phone: this.phone,
        area_code: this.areaCode,
      };
      const verifyFun = this.validateType === 'bind' ? api.dhuman.groupCaptcha : verifyCaptcha
      verifyFun(params)
        .then((res) => {
          this.puzzleTips = '验证通过';
          this.isSuccess = true;
          this.verifying = false;
          setTimeout(() => {
            this.closeSlideDialog();
            this.$emit('success');
          }, 1000);
        })
        .catch((err) => {
          if(err.tip){
            this.$notify(err.tip)
          }
          this.verifying = false;
          this.puzzleTips = '验证失败';
          this.isSuccess = false;
          if ([12111013, 12111019, 12101013].includes(err.code)) {
            setTimeout(() => {
              this.closeSlideDialog();
              this.isSuccess = null;
              this.$emit('fail');
            }, 1000);
          } else {
            setTimeout(() => {
              this.puzzleTips = '';
              this.isSuccess = null;
              this.refresh();
              this.$emit('fail');
            }, 1000);
          }
        });
    },
    // 关闭验证码对话框
    closeSlideDialog() {
      this.$emit('close');
    },
    // 图片加载完毕
    onload(type) {
      // console.log(`图片bg是否加载完毕？ ${this.$refs.sliderBg.complete}`);
      // console.log(`图片block是否加载完毕？ ${this.$refs.sliderBlock.complete}`);
      type === 'bg' ? (this.imgLoaded[0] = true) : (this.imgLoaded[1] = true);
      if (this.imgLoaded.every((item) => item)) {
        this.isLoading = false;
        this.isDisable = false;
        if (this.browserInfo.device === this.TARGET_DEVICE) {
          this.top = captchaInfo.percent_y * VERIFY_HEIGHT + 'px';
          console.log('this.top=', this.top, captchaInfo.percent_y);
        } else {
          this.top = captchaInfo.percent_y * VERIFY_HEIGHT + 'px';
          console.log('this.top=', this.top, captchaInfo.percent_y);
        }
        this.captcha_id = captchaInfo.captcha_id;
      }
    },
    // 获取验证码失败
    captchaErr(type, err) {
      this.loadFail = true;
      this.isLoading = false;
    },
    // 获取验证码图片
    getCaptchas() {
      return new Promise((resolve, reject) => {
        if (this.validateType === 'bind') {
          if(!this.phone){
            return false
          }
          api.dhuman.groupImageCaptcha({
            area_code: this.areaCode,
            phone: this.phone
          }).then(res => {
            if (  
                this.baseImg !== res.captcha.base_img &&
                this.puzzleImg !== res.captcha.puzzle_img
              ) {
                this.baseImg = res.captcha.base_img;
                this.puzzleImg = res.captcha.puzzle_img;
                captchaInfo = res.captcha;
                this.refreshCount = 0;
                resolve();
                this.$emit('show')
              } else {
                this.getCaptchas();
              }
          }).catch((err) => {
              reject({
                obj: err,
                type: 'err',
              });
            });
        } else {
          getImgCaptcha({})
            .then((data) => {
              const res = data.data.data
              console.log(res)
              if (
                this.baseImg !== res.account.base_img &&
                this.puzzleImg !== res.account.puzzle_img
              ) {
                this.baseImg = res.account.base_img;
                this.puzzleImg = res.account.puzzle_img;
                captchaInfo = res.account;
                this.refreshCount = 0;
                resolve();
              } else {
                this.getCaptchas();
              }
            })
            .catch((err) => {
              reject({
                obj: err,
                type: 'err',
              });
            });
        }
      });
    },
  },
};
</script>

<style scoped lang="stylus">
.icon-ziyuanxhdpi{
  cursor pointer
  font-weight 500
  color #fff
  padding 4px
  width 24px
  height 24px
  &:hover{
    background: rgba(153,153,153,0.3);
  }
}
.cancle--blue
  -moz-user-select none /* 火狐 */
  -webkit-user-select none /* webkit浏览器 */
  -ms-user-select none /* IE10 */
  -khtml-user-select none /* 早期浏览器 */
  user-select none
.__verify-container
  padding 0px 16px 16px 16px;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.3);
  border-radius: 2px;
  opacity: 1;
  width 272px
.__verify-header
  height 50px;
  display flex
  justify-content space-between
  align-items center
  border-bottom 1px solid #eee;
  margin-bottom 12px
  ._title
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC, Source Han Sans CN;
    font-weight: normal;
    color: #333333
  .deep-icon
    cursor pointer
    padding: 4px
    width 24px;
    height 24px;
    font-size 20px
    color #666
    &:hover
      color #333
.component
  position relative
  .__loading-mask 
    position absolute 
    background rgba(255, 255, 255, .3)
    width 100% 
    height 180px
    z-index 10001
  .img-box-mobile
    position relative
    width 640px
    height 360px
  .img-box
    position relative
    width 400px
    height 225px
  .btn-group-mobile, .btn-group
    position absolute
    display flex
    justify-content flex-end
    z-index 9999
    img
      cursor pointer
      background-repeat no-repeat
      background-size cover
      background-position center
  .btn-group-mobile
    width 154px
    height 78px
    top 6px
    right 8px
    img
      width 74px
      height 78px
  .btn-group
    width 77px
    height 39px
    top 3px
    right 4px
    img
      width 37px
      height 39px
  .img1, .img1-mobile
    width 100%
    height 100%
    border-radius 2px;
  .img1-mobile
    border-radius 34px
  .img2-mobile
    position absolute
    width 132px
    height 128px
  .img2
    position absolute
    width 48px
    height 48px
    cursor pointer
  .cover
    position absolute
    width 100%
    height 100%
    top 0
    left 0
    z-index 2
  .load-fail
    background-color #F2F2F2
    border-radius 13px
    padding-top 51px
    .load-fail-img
      margin 0 auto
      width 61px
      height 48px
      background-image url('~@/assets/images/components/load-fail.png')
      background-size cover
    .load-fail-text
      margin-top 15px
      font-size 14px
      font-family PingFang SC
      line-height 25px
      color #999
      text-align center
  .tips-box
    font-size 16px
    padding-left 10px
    height 30px
  .tips-box-mobile
    font-size 24px
    padding-left 20px
    height 50px
  .tips-box, .tips-box-mobile
    position absolute
    bottom 0
    left 0
    display flex
    align-items center
    width 100%
    color #fff
    height 100%
    display flex
    align-items center
    justify-content center
    img
      width 106px;
      height auto;
  .slider
    position relative
    width 100%
    border-radius 18px
    margin-top 12px
    height: 40px;
    background: #F8F9FB;
    border-radius: 2px;
    .left-slider{
      position absolute
      background-color red;
      top: 0;
      height 100%;
      left: 0
      background-color: #E8F2FF;
      border-radius: 4px 0px 0px 4px;
      opacity: 1;
      border: 1px solid #177FFF;
      transition background-color .15s
      &.is-moving{
        border: 1px solid #177FFF;
        background-color: #E8F2FF;
      }
      &.is-success{
        border: 1px solid #36CA5F !important;
        background-color: #EBFAEF !important;
      }
      &.is-fail{
        border: 1px solid #E64340 !important;
        background-color: #FCECEC !important;
      }
    }
  .slider-mobile
    width 640px
    height 70px
    margin-top 24px
    border-radius 36px
  .slider, .slider-mobile
    position relative
    background #F2F2F2
    @extend .cancle--blue
    &.slider--disable
      pointer-events none
    .text
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC, Source Han Sans CN;
      font-weight: 400;
      color: #999999;
    .text-mobile
      font-size 34px
    .text, .text-mobile
      display flex
      justify-content center
      align-items center
      height 100%
      color #888
      text-align center
      font-weight 500
    .drag
      top: 0
      position absolute
      width 40px
      height 100%;
      left 20px
      background-color: #fff;
      border-radius: 2px;
      opacity: 1;
      box-shadow: 0px 0px 4px 0px rgba(3,3,3,0.1);
      display flex
      align-items center
      justify-content center
      transition background-color .15s
      cursor pointer
      .el-icon-d-arrow-right
        color white
        font-size: 24px;
        font-weight bold
      &:hover{
        background-color: #177FFF;
        color #fff
      }
      &.is-moving{
        background-color: #177FFF;
        color #fff
      }
      &.is-success{
        background-color #36CA5F !important
        color #ffffff !important
      }
      &.is-fail{
        color #ffffff !important
        background-color #E64340 !important
      }

    .drag-mobile
      width 128px
      height 70px
      left 40px
      border-radius 36px
    .bar-mobile
      width 4px
      height 34px
      background #fff
    .bar-mobile + .bar-mobile
      margin-left 14px
    .bar
      width 2px
      height 17px
      background #fff
    .bar + .bar
      margin-left 7px
</style>
