var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"__login"},[(!_vm.isOEM)?_c('div',{staticClass:"__text-desc"},[_c('div',{staticClass:"__text-1"},[_vm._v("150秒免费时长")]),_vm._m(0)]):_c('div',{staticClass:"__text-desc __oem"},[_c('img',{staticStyle:{"width":"1.88rem"},attrs:{"src":require("@/assets/images/fenfenzhong.png"),"alt":""}}),_vm._m(1)]),_c('div',{staticClass:"login-form-container",style:({marginTop: _vm.isOEM ? '0.81rem': '0.41rem'})},[_c('van-field',{staticClass:"__inner-input",attrs:{"type":"number","label":"","placeholder":"手机号","maxlength":11,"label-width":"64px"},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}},[_c('template',{slot:"label"},[_c('div',{staticClass:"__area-code",on:{"click":function($event){_vm.isShowPicker = true}}},[_c('div',{staticClass:"__code"},[_vm._v(_vm._s(_vm.formData.area_code.text))]),_c('img',{staticStyle:{"width":"12px","margin-left":"8px","margin-top":"1px"},attrs:{"src":require("@/assets/images/1.10.0/arrow.png"),"alt":""}})])])],2),_c('van-field',{staticClass:"__inner-input",attrs:{"type":"number","label":"","maxlength":6,"placeholder":"手机验证码"},model:{value:(_vm.formData.code),callback:function ($$v) {_vm.$set(_vm.formData, "code", $$v)},expression:"formData.code"}},[_c('template',{slot:"button"},[_c('div',{staticClass:"__msg-code-btn",style:({
            color: _vm.msgCodeBtn === _vm.DEFAULT_BTN_TEXT ? '#177fff' : '#999',
          }),on:{"click":_vm.getImageCheck}},[_vm._v(" "+_vm._s(_vm.msgCodeBtn)+" ")])])],2),_c('van-button',{staticClass:"__submit",on:{"click":_vm.submitLogin}},[_vm._v(" 登录/注册 ")])],1),_c('div',{staticClass:"__agreements"},[_vm._v(" 注册即代表同意"),_c('span',{staticClass:"__agreement",on:{"click":function($event){return _vm.$router.push({
          path: '/agreement',
          query: {
            doc: 'user',
          },
        })}}},[_vm._v("《用户协议》")]),_vm._v("及"),_c('span',{staticClass:"__agreement",on:{"click":function($event){return _vm.$router.push({
          path: '/agreement',
          query: {
            doc: 'privacy',
          },
        })}}},[_vm._v("《隐私政策》")])]),_c('van-popup',{attrs:{"round":"","position":"bottom"},model:{value:(_vm.isShowPicker),callback:function ($$v) {_vm.isShowPicker=$$v},expression:"isShowPicker"}},[_c('van-picker',{attrs:{"title":"","columns":_vm.columns,"show-toolbar":""},on:{"cancel":function($event){_vm.isShowPicker = false},"confirm":_vm.onConfirm}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isImageCheckShow),expression:"isImageCheckShow"}],staticClass:"__verify-mask"},[_c('Verify',{ref:"Verify",attrs:{"phone":_vm.formData.phone,"area-code":_vm.formData.area_code.value},on:{"success":_vm.handleCodeSuccess,"close":_vm.handleVerifyClose}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDialogShow),expression:"isDialogShow"}],staticClass:"__dialog-mask"},[_c('div',{staticClass:"__dialog"},[_c('div',{staticClass:"__content"},[_vm._v(" 您已注册，请前往电脑端体验"),_c('br'),_vm._v(_vm._s(_vm.brand.domain_name)+" ")]),_c('div',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.brand.domain_name),expression:"brand.domain_name",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"}],staticClass:"__btn-warp"},[_c('div',{staticClass:"__btn-text"},[_vm._v("复制地址")])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',{staticClass:"__text-2"},[_vm._v("5次AI写稿")]),_c('li',{staticClass:"__text-2"},[_vm._v("不限次数使用数字人模特")]),_c('li',{staticClass:"__text-2"},[_vm._v("不限次数使用全平台视频模板")]),_c('li',{staticClass:"__text-2"},[_vm._v("不限次数使用AI语音合成")]),_c('li',{staticClass:"__text-2"},[_vm._v("……")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticStyle:{"margin-top":"12px"}},[_c('li',{staticClass:"__text-3"},[_vm._v("为您"),_c('span',{staticStyle:{"color":"#FE5913"}},[_vm._v("低成本")]),_vm._v("打造数字分身")]),_c('li',{staticClass:"__text-3"},[_vm._v("随时随地"),_c('span',{staticStyle:{"color":"#FE5913"}},[_vm._v("生成口播视频")])])])
}]

export { render, staticRenderFns }