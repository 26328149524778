<template>
  <div class="dhuman-custom">
    <div class="__scroll">
      <img
        class="__intro-image"
        :src="
          'https://cdn.img.deepsound.cn/config/langdu/dhuman_image/custom2.jpg?t=' +
          imageFix
        "
      />
    </div>
    <div class="__btn-warp">
      <div
        class="__btn-box"
        @click="handleClick('call')"
        :class="{ active: 'call' === activeOpt }"
      >
        <img
          mode="widthFix"
          class="__icon"
          :src="
            'https://cdn.img.deepsound.cn/config/langdu/dhuman_image/call.png?t=' +
            imageFix
          "
        />
        <div class="__text">电话联系</div>
      </div>
      <div
        class="__btn-box"
        @click="handleClick('weixin')"
        :class="{ active: 'weixin' === activeOpt }"
      >
        <img
          mode="widthFix"
          class="__icon"
          :src="
            'https://cdn.img.deepsound.cn/config/langdu/dhuman_image/weixin.png?t=' +
            imageFix
          "
        />
        <div class="__text">微信联系</div>
      </div>
    </div>
    <div class="__weixin-mask" @click="clickMask" v-if="dialogShow">
      <div class="__panel" @click.stop="()=> {}">
        <img
          mode="widthFix"
          class="__custom_weixin_header"
          :src="
            'https://cdn.img.deepsound.cn/config/langdu/dhuman_image/custom_weixin_header.png?t=1' +
            imageFix
          "
        />
        <img
          mode="widthFix"
          class="__custom_weixin_title"
          :src="
            'https://cdn.img.deepsound.cn/config/langdu/dhuman_image/custom_weixin_title.png?t=' +
            imageFix
          "
        />
        <div class="__ewm-container">
          <div class="__ewm-warp">
            <img
              class="__ewm"
              mode="widthFix"
              show-menu-by-longpress
              :src="
                'https://cdn.img.deepsound.cn/config/langdu/dhuman_image/custom_weixin.png?t=121'
              "
            />
          </div>
          <div class="__ewm-text">扫码添加微信</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageFix: '160',
      activeOpt: '',
      dialogShow: false
    };
  },
  mounted() {},
  methods: {
    clickMask(){
      this.dialogShow = false
    },
    handleClick(opt) {
      if (opt === 'call') {
        this.makePhoneCall();
      }
      if(opt === 'weixin'){
        this.dialogShow = true
      }
    },

    makePhoneCall() {
      const phone = '17607121873';
      window.location.href = 'tel:' + phone;
    }
  }
};
</script>

<style scoped lang="stylus">
.dhuman-custom {
  width: 100vw;
  height: 100vh;
  padding-right: env(safe-area-inset-right);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  .__scroll {
    padding-bottom 50px
  }
  .__intro-image {
    width: 100%;
  }
  .__btn-warp {
    position fixed
    height: 50px;
    display: flex;
    background-color #fff
    width 100%
    bottom 0
    .__btn-box {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      &.active {
        background-color: #f5f5f5;
      }
      .__icon {
        width: 20px;
      }
      .__text {
        margin-left: 4px;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 16px;
      }
    }
  }
  .__weixin-mask {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .__panel {
      width: 280px;
      height: 282px;
      background: #ffffff;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.2);
      border-radius: 10px 10px 10px 10px;
      position: relative;
      .__custom_weixin_header {
        width: 100%;
        margin-top: -82px;
      }
      .__custom_weixin_title {
        width: calc(100% - 30px);
        position: absolute;
        top: 42px;
        left: 15px;
      }
      .__ewm-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        flex-direction: column;
        .__ewm-warp {
          width: 114px;
          height: 114px;
          background: rgba(153, 153, 153, 0.12);
          border-radius: 4px 4px 4px 4px;
          padding: 6px;
          .__ewm {
            width: 100%;
            border-radius: 4px ;
          }
        }
        .__ewm-text{
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 23px;
          margin-top: 12px;
        }
      }
    }
  }
}
</style>
