<template>
  <div
    class="__coop-box"
  >
    <img class="__image" :src="item.image" alt="" />
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {},
};
</script>

<style scoped lang="stylus">
.__coop-box
  margin .07rem
  display inline-block
  .__image
    width 1.05rem
    height .52rem
</style>
