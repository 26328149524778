<template>
  <div class="__regist_success" :style="{paddingTop: isOEM ? '0.8rem': '0'}">
    <img v-if="!isOEM" class="__logo" src="~@/assets/images/1.10.0/logo.png" alt="" />
    <div class="__text-1" :style="{marginTop: isOEM ? '0': '0.8rem'}">注册成功</div>
    <div v-if="!isOEM" class="__text-2">已发放免费时长到账号，快去电脑端体验吧</div>
    <div v-if="isOEM" class="__text-2">快去电脑端体验吧</div>
    <img class="__lili" src="~@/assets/images/1.10.0/lili.png" alt="" />
    <div class="__text-3">{{brand.domain_name}}</div>
    <div class="__btn-warp">
      <div
        class="__btn"
        v-clipboard:copy="brand.domain_name"
        v-clipboard:success="onCopy"
      >
        <div class="__btn-text">复制地址</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      host: location.hostname
    };
  },
  computed: {
    ...mapGetters(['isOEM', 'brand'])
  },
  mounted() {},
  methods: {
    onCopy(){
      this.$notify({type: 'success', message: '复制成功'})
    }
  },
};
</script>

<style scoped lang="stylus">
.__regist_success
  min-height 100vh
  background-image url('~@/assets/images/1.10.0/bg1.png')
  backgroud-size cover
  padding-bottom .4rem
  max-width 600px
  position relative
  margin 0 auto
  .__logo
    width .98rem
    height .3rem
    margin-top .16rem
    margin-left .24rem
  .__text-1
    height: 37px;
    font-size: 28px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #2448FF;
    line-height: 33px;
    letter-spacing: 1px;
    text-align center
    margin-top .8rem
  .__text-2
    height: 28px;
    font-size: 15px;
    font-family: Microsoft YaHei-Light, Microsoft YaHei;
    font-weight: 300;
    color: #233E86;
    line-height: 28px;
    letter-spacing: 1px;
    text-align center
    margin-top .08rem
  .__lili
    margin-top .68rem
    max-width 100%
    width 100%
  .__text-3
    height: 20px;
    font-size: 15px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #233E86;
    line-height: 18px;
    text-align center
    margin-top .8rem
  .__btn-warp
    padding 0 .32rem
    margin-top .2rem
    .__btn
      height: .46rem;
      background: #177FFF;
      width 100%
      display flex
      align-items center
      justify-content center
      .__btn-text
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 19px;
</style>
