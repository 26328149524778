<template>
  <div class="__login">
    <div class="__text-desc" v-if="!isOEM">
      <div class="__text-1">150秒免费时长</div>
      <ul>
        <li class="__text-2">5次AI写稿</li>
        <li class="__text-2">不限次数使用数字人模特</li>
        <li class="__text-2">不限次数使用全平台视频模板</li>
        <li class="__text-2">不限次数使用AI语音合成</li>
        <li class="__text-2">……</li>
      </ul>
    </div>
    <div v-else class="__text-desc __oem">
      <img src="~@/assets/images/fenfenzhong.png" style="width: 1.88rem;" alt="">
      <ul style="margin-top: 12px;">
        <li class="__text-3">为您<span style="color: #FE5913;">低成本</span>打造数字分身</li>
        <li class="__text-3">随时随地<span style="color: #FE5913;">生成口播视频</span></li>
      </ul>
    </div>
    <div class="login-form-container" :style="{marginTop: isOEM ? '0.81rem': '0.41rem'}">
      <van-field
        v-model="formData.phone"
        type="number"
        label=""
        placeholder="手机号"
        :maxlength="11"
        label-width="64px"
        class="__inner-input"
      >
        <template slot="label">
          <div class="__area-code" @click="isShowPicker = true">
            <div class="__code">{{ formData.area_code.text }}</div>
            <img
              style="width: 12px; margin-left: 8px; margin-top: 1px"
              src="~@/assets/images/1.10.0/arrow.png"
              alt=""
            />
          </div>
        </template>
      </van-field>
      <van-field
        class="__inner-input"
        v-model="formData.code"
        type="number"
        label=""
        :maxlength="6"
        placeholder="手机验证码"
      >
        <template slot="button">
          <div
            class="__msg-code-btn"
            :style="{
              color: msgCodeBtn === DEFAULT_BTN_TEXT ? '#177fff' : '#999',
            }"
            @click="getImageCheck"
          >
            {{ msgCodeBtn }}
          </div>
        </template>
      </van-field>
      <van-button class="__submit" @click="submitLogin"> 登录/注册 </van-button>
    </div>

    <div class="__agreements">
      注册即代表同意<span
        class="__agreement"
        @click="
          $router.push({
            path: '/agreement',
            query: {
              doc: 'user',
            },
          })
        "
        >《用户协议》</span
      >及<span
        class="__agreement"
        @click="
          $router.push({
            path: '/agreement',
            query: {
              doc: 'privacy',
            },
          })
        "
        >《隐私政策》</span
      >
    </div>
    <van-popup v-model="isShowPicker" round position="bottom">
      <van-picker
        title=""
        :columns="columns"
        @cancel="isShowPicker = false"
        @confirm="onConfirm"
        show-toolbar
      />
    </van-popup>
    <div class="__verify-mask" v-show="isImageCheckShow">
      <Verify
        @success="handleCodeSuccess"
        @close="handleVerifyClose"
        :phone="formData.phone"
        :area-code="formData.area_code.value"
        ref="Verify"
      />
    </div>
    <div class="__dialog-mask" v-show="isDialogShow">
      <div class="__dialog">
        <div class="__content">
          您已注册，请前往电脑端体验<br />{{brand.domain_name}}
        </div>
        <div
          class="__btn-warp"
          v-clipboard:copy="brand.domain_name"
          v-clipboard:success="onCopy"
        >
          <div class="__btn-text">复制地址</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Verify from '@/components/Verify.vue';
import * as api from '@/api';
import { mapGetters } from 'vuex'
const DEFAULT_BTN_TEXT = '点击获取';
export default {
  components: {
    Verify,
  },
  computed: {
    ...mapGetters(['isOEM', 'brand'])
  },
  data() {
    return {
      formData: {
        phone: '',
        area_code: { text: '+86', value: '86' },
        code: '',
      },
      columns: [
        { text: '+86', value: '86' },
        { text: '+852', value: '852' },
        { text: '+853', value: '853' },
        { text: '+886', value: '886' },
      ],
      isShowPicker: false,
      msgCodeBtn: DEFAULT_BTN_TEXT,
      codeCount: 60,
      timer: null,
      isImageCheckShow: false,
      isDialogShow: false,
      DEFAULT_BTN_TEXT,
    };
  },
  mounted() {
    this.$refs.Verify.refresh();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  methods: {
    handleCodeSuccess() {
      this.timer = setInterval(() => {
        this.codeCount--;
        this.msgCodeBtn = this.codeCount + 's';
        if (this.codeCount < 1) {
          this.msgCodeBtn = DEFAULT_BTN_TEXT;
          if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
            this.codeCount = 60;
          }
        }
      }, 1000);
    },
    onCopy() {
      this.$notify({ type: 'success', message: '复制成功' });
      this.isDialogShow = false;
    },
    handleVerifyClose() {
      this.isImageCheckShow = false;
    },
    onConfirm(val) {
      this.formData.area_code = val;
      this.isShowPicker = false;
    },
    // 点击获取验证码，打开图形验证码面板
    getImageCheck() {
      if (this.msgCodeBtn !== DEFAULT_BTN_TEXT) {
        return false;
      }
      const check = this.phoneCheck();
      if (!check) {
        return false;
      }
      this.isImageCheckShow = true;
      this.$nextTick(() => {
        this.$refs.Verify.refresh();
      });
    },
    // 参数检查
    phoneCheck() {
      if (['852', '853', '886'].includes(this.formData.area_code.value)) {
        if (this.formData.phone.length < 8) {
          this.$notify('请输入正确的手机号');
          return false;
        }
      }
      if (['86'].includes(this.formData.area_code.value)) {
        if (this.formData.phone.length < 11) {
          this.$notify('请输入正确的手机号');
          return false;
        }
      }
      return true;
    },
    msgCodeCheck() {
      if (this.formData.code === '') {
        this.$notify('请输入手机验证码');
        return false;
      }
      return true;
    },
    submitLogin() {
      const phoneVlidate = this.phoneCheck();
      if (!phoneVlidate) {
        return false;
      }
      const codeVlidate = this.msgCodeCheck();
      if (!codeVlidate) {
        return false;
      }
      api
        .login({
          phone: this.formData.phone,
          captcha: this.formData.code,
          area_code: this.formData.area_code.value,
        })
        .then((res) => {
          const account = res.data.data.account;
          const user_id = account.user_id;
          api.loginInfo(user_id).then((res) => {
            const userInfo = res.data.data;
            if (userInfo && userInfo.user_profile.is_first_login) {
              this.$router.push({
                path: '/regist_success',
              });
            } else {
              this.isDialogShow = true;
            }
            this.resetForm();
          });
        })
        .catch((err) => {
          this.$notify(err.tip);
        });
    },
    // 重置
    resetForm() {
      this.formData.code = '';
      this.formData.phone = '';
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.codeCount = 60;
      this.msgCodeBtn = DEFAULT_BTN_TEXT;
    },
  },
};
</script>
<style lang="stylus">
input.van-field__control::-webkit-input-placeholder
  color: #8D99AC !important
</style>
<style scoped lang="stylus">
.__dialog-mask
  position fixed
  width 100vw
  height 100vh
  z-index 1001
  background rgba(0,0,0, .3)
  top 0
  left 0
  display flex
  align-items center
  justify-content center
  .__dialog
    background-color #fff
    padding .3rem
    border-radius 4px
    .__content
      color #666
      font-size .16rem
      line-height 24px
      text-align center
    .__btn-warp
      height: .46rem;
      background: #177FFF;
      width 100%
      display flex
      align-items center
      justify-content center
      margin-top .24rem
      .__btn-text
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 19px;
.__verify-mask
  position fixed
  width 100vw
  height 100vh
  z-index 1001
  background rgba(0,0,0, .3)
  top 0
  left 0
  display flex
  align-items center
  justify-content center
.__agreements
  padding-left .32rem
  margin-top .34rem
  height: 20px;
  font-size: 12px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #999999;
  line-height: 20px
  .__agreement
    font-size: 12px;
.__login
  height 100vh
  width 100vw
  max-width 600px
  position relative
  margin 0 auto
  background-image url('@/assets/images/1.10.0/login-bg.png')
  background-size 100% auto
  background-repeat no-repeat
  .__text-desc
    padding .26rem .24rem 0 .24rem
    &.__oem
      padding .56rem .24rem 0 .24rem
    .__text-1
      height: .27rem;
      font-size: .20rem;
      font-family: CKTKingKong-Bold, CKTKingKong;
      font-weight: bold;
      color: #177FFF;
      line-height: .23rem;
      letter-spacing: 1px;
    .__text-2
      font-size: 12px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #00499D;
      line-height: 22px;
    .__text-3 
      color: #375AE6;
      line-height: 28px;
      font-size: .14rem;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
  .login-form-container
    margin-top .41rem
    background: #FFFFFF;
    border-radius: 20px 20px 0px 0px;
    padding .23rem .32rem 0 .32rem
  .van-cell
    padding 10px 0
    margin-top .15rem
    border-bottom 1px solid #EBEBEB
    &:after
      display none
  .__area-code
    position relative
    font-size: .16rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #8D99AC;
    display flex
    align-items center
    .__code
      font-size: .16rem;
    &:after
      content ''
      position absolute
      width 1px
      height .12rem
      right 0
      background #EBEBEB
      top 50%
      transform translateY(-50%)
  .__msg-code-btn
    font-size: .16rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #177FFF;
  .__inner-input
    font-size .16rem
    >>>.van-field__body, >>>.van-cell__title
      font-size .16rem
  .__submit
    background-color #177fff
    color #fff
    height: 46px;
    width 100%
    border-radius 4px
    border-color #177fff
    margin-top .43rem
</style>
