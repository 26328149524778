<template>
  <div class="__section-1">
    <img
      v-if="!isOEM"
      class="__logo"
      src="~@/assets/images/1.10.0/logo.png"
      alt=""
    />
    <h2 class="__title-text-1">用数字人做视频</h2>
    <h1 class="__title-text-2">
      <span>分分钟</span><span class="__blue">干完一天的活</span>
    </h1>
    <img class="__lili" src="~@/assets/images/1.10.0/lili.png" alt="" />
    <div v-if="!isOEM" class="__tip-container">
      <div class="__tip-box" style="margin-top: 0">
        <div class="__tip-box-text">
          小米投资，中科院博士带队攻关，掌握全栈技术
        </div>
      </div>
      <div class="__tip-box" style="margin-top: 0.12rem">
        <div class="__tip-box-text">
          深受<span style="color: #fe5913">小米、OPPO、中科院、奥美广告</span
          >等客户信赖
        </div>
      </div>
    </div>
    <div v-else class="__tip-container">
      <div class="__tip-box" style="margin-top: 0">
        <div class="__tip-box-text">
          录制<span style="color: #fe5913">8min视频</span>，即可<span style="color: #fe5913">1:1复刻</span>真人，终身使用
        </div>
      </div>
      <div class="__tip-box" style="margin-top: 0.12rem">
        <div class="__tip-box-text">
          输入文字或音频，数字人即可说话，神态<span style="color: #fe5913">堪比真人</span>
        </div>
      </div>
    </div>
    <div class="__info-box">
      <div class="__box-item">
        <div class="__text-1">95+</div>
        <div class="__text-2">数字人还原度，业界领先</div>
      </div>
      <div class="__box-item">
        <div class="__text-1">20,000,000+</div>
        <div class="__text-2">累计用户</div>
      </div>
      <div class="__box-item">
        <div class="__text-1">SaaS/API/OEM</div>
        <div class="__text-2">支持多种合作方式</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {};
  },
  mounted() {
    console.log(this.isOEM);
  },
  methods: {},
  computed: {
    ...mapGetters(['isOEM']),
  },
};
</script>

<style scoped lang="stylus">
.__section-1
  min-height 100vh
  background-image url('~@/assets/images/1.10.0/bg1.png')
  backgroud-size cover
  .__tip-container
    display flex
    flex-direction column
  .__tip-box
    height: 26px;
    border-radius: 4px 4px 4px 4px;
    margin-top .12rem .24rem .12rem .24rem
    text-align center
    display inline-block
    .__tip-box-text
      border-radius: 4px 4px 4px 4px;
      display inline-block
      background: linear-gradient(90deg, rgba(189,209,249,0) 0%, #BDD1F9 20%, #BDD1F9 80%, rgba(189,209,249,0) 100%)
      height: 26px;
      font-size: .14rem;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #375AE6;
      line-height: 26px;
      padding 0 .12rem
  .__logo
    width .98rem
    height .3rem
    margin-top .16rem
    margin-left .24rem
  .__title-text-1
    font-size: 15px;
    font-family: Microsoft YaHei-Light, Microsoft YaHei;
    font-weight: 300;
    color: #233E86;
    line-height: 17px;
    letter-spacing: 12px
    text-align center
    padding-top .4rem
  .__title-text-2
    font-size: .29rem;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #FE5913;
    line-height: 34px;
    text-align center
    line-height: 38px;
    margin-top .07rem
    span
      font-size: .29rem;
      line-height: 38px;
    .__blue
      color #2448FF
  .__lili
    margin-top .25rem
    max-width 100%
    width 100%
  .__info-box
    position relative
    margin 0.32rem .24rem 0 .24rem
    background: #FFFFFF;
    box-shadow: 0px 10px 18px 0px rgba(21,30,73,0.1);
    border-radius: 8px 8px 8px 8px;
    .__box-item
      padding 0 .12rem 0 .12rem
      border-bottom 1px solid #DEEDFF
      display flex
      justify-content space-between
      align-items center
      height .42rem
      // &:last-child
      //   border none
      //   .__text-2
      //     margin .04rem 0 0rem 0
      // &:first-child
      //   .__text-1
      //     margin-top 0
      .__text-1
        height: 26px;
        font-size: .16rem;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #0762CB;
        line-height: 23px;
        position relative
        padding-left .08rem
        &:before
          content ''
          position absolute
          top 4px
          left 0
          width: 2px;
          height: 16px;
          background: #21CAB6;
      .__text-2
        font-size: .14rem;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #233E86;
        line-height: 16px;
        letter-spacing: 1px
</style>
