<template>
  <div class="__section-2">
    <div class="__sec-title">
      <div class="__text">数字人1:1真人还原</div>
    </div>
    <div class="__sub-title">效果业界领先</div>
    <div class="__title-desc" v-if="!isOEM">中科院博士带队攻关，掌握数字人全栈技术</div>
    <div class="__advant-box-warp">
      <div class="__advant-item">
        <img
          class="__advant-img"
          src="~@/assets/images/1.10.0/icon-1.png"
          alt=""
        />
        <div class="__advant-title">门槛低</div>
        <div class="__advant-desc">8分钟视频即可克隆自己/他人</div>
      </div>
      <div class="__advant-item">
        <img
          class="__advant-img"
          src="~@/assets/images/1.10.0/icon-2.png"
          alt=""
        />
        <div class="__advant-title">效果好</div>
        <div class="__advant-desc">形象逼真，动作自然，还原度评分95+</div>
      </div>
      <div class="__advant-item">
        <img
          class="__advant-img"
          src="~@/assets/images/1.10.0/icon-3.png"
          alt=""
        />
        <div class="__advant-title">成本低</div>
        <div class="__advant-desc">千元级数字人定制，绝对的价格优势</div>
      </div>
    </div>
    <VideoSwiper v-if="!isQuark" />
    <!-- 视频展示 -->
    <div class="__video-display" v-if="isQuark">
      <van-collapse
        @change="handleChange"
        :border="false"
        v-model="activeNames"
      >
        <van-collapse-item
          :title="item.name"
          :name="item.key"
          v-for="item in collapseItems"
          :key="item.key"
        >
          <div
            class="__video-box"
            :class="{ __horizontal: item.size === '9:16' }"
          >
            <XGvideoPlayer
              v-bind="item"
              style="margin-bottom: 0"
              :key="'video' + item.url"
              :style="{
                width: item.size === '16:9' ? '3.12rem' : '1.65rem',
                height: item.size === '16:9' ? '1.75rem' : '2.92rem',
              }"
              :ref="'video' + item.key"
              :video-key="item.key"
              @play="handlePlay"
            />
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
import XGvideoPlayer from '@/components/XGvideoPlayer.vue';
const VIDEO_CDN_BASEPATH =
  'https://cdn.img.deepsound.cn/config/dhuman/videos/sec3/';
const version = '?=' + '1.10.0';
import VideoSwiper from './components/VideoSwiper.vue';
import videojs from 'video.js'
import { mapGetters } from "vuex";
export default {
  components: {
    XGvideoPlayer,
    VideoSwiper,
  },
  computed: {
    ...mapGetters(['isOEM'])
  },
  data() {
    return {
      activeNames: [],
      collapseItems: [
        {
          key: 'zhishikoubo',
          name: '知识口播',
          url: VIDEO_CDN_BASEPATH + 'zhishikoubo.mp4' + version,
          size: '16:9',
          poster: VIDEO_CDN_BASEPATH + 'poster/zhishikoubo.png',
        },
        {
          key: 'jinrongbaoxian',
          name: '金融保险',
          url: VIDEO_CDN_BASEPATH + 'jinrongbaoxian.mp4' + version,
          size: '9:16',
          poster: VIDEO_CDN_BASEPATH + 'poster/jinrongbaoxian.png',
        },
        {
          key: 'qiyexuanchuan',
          name: '企业宣传',
          url: VIDEO_CDN_BASEPATH + 'qiyexuanchuan.mp4' + version,
          size: '16:9',
          poster: VIDEO_CDN_BASEPATH + 'poster/qiyexuanchuan.png',
        },
        {
          key: 'zhibodaihuo',
          name: '直播带货',
          url: VIDEO_CDN_BASEPATH + 'zhibodaihuo.mp4' + version,
          size: '9:16',
          poster: VIDEO_CDN_BASEPATH + 'poster/zhibodaihuo.png',
        },
        {
          key: 'xinwenbobao',
          name: '新闻播报',
          url: VIDEO_CDN_BASEPATH + 'xinwenbobao.mp4' + version,
          size: '16:9',
          poster: VIDEO_CDN_BASEPATH + 'poster/xinwenbobao.png',
        },
      ],
      isQuark: false,
    };
  },
  created() {
    const isQuark = !!navigator.userAgent.match(/Quark/);
    const isUC = !!navigator.userAgent.match(/UCBrowser/);
    this.isQuark = isQuark || isUC;
    if(this.isOEM){
      this.collapseItems.splice(2,1)
    }
  },
  methods: {
    handlePlay(key) {
      this.collapseItems.forEach((item) => {
        if (item.key !== key) {
          const component = this.$refs['video' + item.key];
          if (component && component[0]) {
            component[0].$pause();
          }
        }
      });
    },
    handleChange(val) {
      this.collapseItems.forEach((item) => {
        if (!val || !val.length) {
          const component = this.$refs['video' + item.key];
          if (component && component[0]) {
            component[0].$pause();
          }
        }
      });
      if (val && val.length) {
        const video = val[val.length - 1];
        this.$nextTick(() => {
          this.collapseItems.forEach((item) => {
            if (item.key !== video) {
              const component = this.$refs['video' + item.key];
              if (component && component[0]) {
                component[0].$pause();
              }
            }
          });
        });
      }
    },
  },
};
</script>

<style scoped lang="stylus">
.__section-2
  min-height 100vh
  background-image url('~@/assets/images/1.10.0/bg2.png')
  background-size 100% auto
  background-repeat no-repeat
  .__advant-box-warp
    display flex
    justify-content space-between
    position relative
    margin .26rem .33rem 0 .33rem
    .__advant-item
      display flex
      align-items center
      flex-direction column
      .__advant-img
        width .68rem
        height .68rem
      .__advant-title
        height: 18px;
        font-size: 14px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #1A1A1A;
        line-height: 16px;
        margin-top 12px
      .__advant-desc
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #818493;
        line-height: 20px;
        margin-top 6px
        width 0.77rem
        text-align center
  .__video-display
    position relative
    margin .2rem .16rem 0 .16rem
    padding-bottom .4rem
    .__video-box
      display flex
      justify-content center
      &.__horizontal
        background-image url('~@/assets/images/1.10.0/video-bg.png')
        background-size cover
    >>>.van-cell, .van-collapse-item--border
      &:after
        display none
    >>>.van-cell--clickable:active
      background-color #177fff
    >>>.van-cell__right-icon
      display none
    >>>.van-cell__title
      text-align center
      height .4rem
      display flex
      align-items center
      justify-content center
      font-size: .16rem;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      span
        font-size: .16rem;
    >>>.van-collapse-item__content
      padding .16rem
    >>>.van-collapse-item
      margin-bottom .12rem
      min-height .6rem
      border-radius: 4px 4px 4px 4px;
      background: linear-gradient(90deg, #FFFFFF 22%, rgba(255,255,255,0.6) 93%);
      box-shadow: 0px 4px 40px 0px rgba(0,0,0,0.05);
      .van-cell
        display flex
        align-items center
        border-radius: 4px 4px 0px 0px;
        position relative
        &:before
          content ''
          position absolute
          top calc(.12rem + 10px)
          right .31rem
          width .16rem
          height .16rem
          background-image url('~@/assets/images/1.10.0/expand_open.png')
          background-size contain
    >>>.van-collapse-item__title--expanded
      background-color #177fff
      .van-cell__title
        color #fff
      &.van-cell
        &:before
          background-image url('~@/assets/images/1.10.0/expand_close.png')
</style>
