<template>
  <div class="__agreement">
    <div class="__name">{{name}}</div>
    <DeepMarkdown ref="DeepMarkdown"></DeepMarkdown>
  </div>
</template>

<script>
import DeepMarkdown from '@/views/components/DeepMarkdown.vue'
// const DOC_BASE_PATH = 'https://cdn.img.deepsound.cn/config/dhuman/docs'
const DOC_BASE_PATH = ''
export default {
  components: {
    DeepMarkdown
  },
  data() {
    return {
      name: ''
    };
  },
  mounted() {
    const docs = [
      {
        name: '用户协议',
        url: DOC_BASE_PATH + '/templates/agreement/user?t=' + Date.now(),
        key: 'user',
      },
      {
        name: '隐私条款',
        url: DOC_BASE_PATH + '/templates/agreement/privacy?t=' + Date.now(),
        key: 'privacy',
      },
    ];
    const findDoc = key => {
      let result = null
      docs.forEach((item, index) => {
        if(item.key === key){
          result = item
        }          
      });
      return result
    }
    const query = this.$route.query;
    if (query.doc) {
      const doc = findDoc(query.doc)
      this.name = doc.name
      this.$refs.DeepMarkdown._initDoc(doc.url);
    }
  },
  methods: {},
};
</script>

<style scoped lang="stylus">
.__agreement 
  padding .24rem
  .__name
    font-size .24rem
    font-weight bold 
</style>
