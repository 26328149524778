var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"__verify-container",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"__verify-header"},[_c('div',{staticClass:"_title"},[_vm._v("请完成安全验证")]),_c('van-icon',{attrs:{"name":"cross"},on:{"click":_vm.closeSlideDialog}})],1),_c('div',{staticClass:"component"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"__loading-mask"}),_c('div',{class:[_vm.browserInfo.device === this.TARGET_DEVICE ? 'img-box' : 'img-box-mobile'],style:({ width: _vm.VERIFY_WIDTH + 'px', height: _vm.VERIFY_HEIGHT + 'px' })},[_c('div',{class:[
          _vm.browserInfo.device === this.TARGET_DEVICE ? 'btn-group' : 'btn-group-mobile',
        ]},[_c('van-icon',{attrs:{"name":"revoke"},on:{"click":_vm.refresh}})],1),_c('div',[_c('img',{ref:"sliderBg",class:[_vm.browserInfo.device === this.TARGET_DEVICE ? 'img1' : 'img1-mobile'],attrs:{"src":_vm.baseImg,"alt":""}}),_c('img',{ref:"sliderBlock",staticClass:"img2",style:({ top: _vm.top, left: _vm.left }),attrs:{"src":_vm.puzzleImg,"draggable":"false"}})]),(_vm.loadFail)?_c('div',{staticClass:"cover load-fail"},[_c('div',{staticClass:"load-fail-img"}),_c('div',{staticClass:"load-fail-text"},[_vm._v("图片加载失败，请点击刷新")])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.puzzleTips),expression:"puzzleTips"}],class:{
          success: _vm.isSuccess,
          fail: !_vm.isSuccess,
          'tips-box': _vm.browserInfo.device === this.TARGET_DEVICE,
          'tips-box-mobile': _vm.browserInfo.device !== this.TARGET_DEVICE,
        }},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSuccess),expression:"isSuccess"}],attrs:{"src":require("@/assets/images/components/success.png"),"alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isSuccess),expression:"!isSuccess"}],attrs:{"src":require("@/assets/images/components/fail.png"),"alt":""}})])]),_c('div',{class:[
        _vm.browserInfo.device === this.TARGET_DEVICE ? 'slider' : 'slider-mobile',
        _vm.isDisable ? 'slider--disable' : '',
      ],style:({ width: _vm.VERIFY_WIDTH + 'px' })},[_c('div',{class:[_vm.browserInfo.device === this.TARGET_DEVICE ? 'text' : 'text-mobile']},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.leftNumber < 4),expression:"leftNumber < 4"}]},[_vm._v("拖动滑块完成填充拼图")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.leftNumber > 2),expression:"leftNumber > 2"}],staticClass:"left-slider",class:{
          'is-moving': _vm.leftNumber > 2,
          'is-success': _vm.isSuccess === true,
          'is-fail': _vm.isSuccess === false,
        },style:({ width: `calc(${_vm.left} + 4px)` })}),_c('div',{ref:"drag",staticClass:"drag",class:{
          'is-moving': _vm.leftNumber > 2,
          'is-success': _vm.isSuccess === true,
          'is-fail': _vm.isSuccess === false,
        },style:({ left: _vm.left })},[_c('div',{staticClass:"deep-icon",class:[_vm.isSuccess === false ? 'icon-guanbi1' : 'icon-arrowRight']})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }