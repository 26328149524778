<template>
  <div class="__xg-video-player" ref="video">
    <div :id="videoId" class="__video-node"></div>
  </div>
</template>

<script>
import Player from 'xgplayer/dist/simple_player';
import volume from 'xgplayer/dist/controls/volume';
import playbackRate from 'xgplayer/dist/controls/playbackRate';
export default {
  props: {
    url: String,
    poster: String,
  },
  data() {
    return {
      videoId: 'video-' + Math.random(),
      player: null,
    };
  },
  created() {
    this.videoId = Math.random() + Date.now();
  },
  computed: {
    isFull() {
      if (this.player) {
        console.log(this.player);
        return true;
      }
      return false;
    },
  },
  methods: {
    initVideo({url, poster}) {
      if(this.player){
        this.player.destroy()
        this.player = null
      }
      let width = this.$refs.video.clientWidth;
      const id = this.videoId;
      let height = 0;
      const player = new Player({
        id,
        url,
        controlPlugins: [volume, playbackRate],
        fitVideoSize: 'auto',
        poster,
        playsinline: true,
        'x5-video-player-type': 'h5',
        'x5-video-orientation': 'portraint',
        width,
        height,
      });

      this.player = player;
      player.on('play', () => {
        this.$emit('play', this.videoKey);
      });
      player.on('replay', () => {
        console.log('replay');
      });
    },
    $pause() {
      this.player.pause();
    },
    $play() {
      this.player.play();
    },
  },
};
</script>

<style scoped lang="stylus">
.__xg-video-player
  min-height 10px
  font-size 0
  overflow hidden
  .__video-node
    background-color transparent
    background transparent
  .__detail
    margin-top .08rem
    .__video-name
      height: .22rem;
      font-size: .16rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: .19rem;
      white-space: nowrap;
      overflow: hidden
      text-overflow: ellipsis;
    .__video-speaker
      height: .17rem;
      font-size: .12rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      line-height: .14rem
</style>
<style lang="stylus">
.xgplayer-start
  zoom 0.75
.xgplayer-replay
  zoom 0.75
.xgplayer-skin-default
  .xgplayer-progress-played
    background-image linear-gradient(-90deg, #177fff, #177fff) !important
.xgplayer-progress-played
  background-image linear-gradient(-90deg, #177fff, #177fff) !important
</style>
