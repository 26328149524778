// getImgCaptcha
// verifyCaptcha
import axios from 'axios'
const baseURL = '/v1.0/user-center'

export function getImgCaptcha(){
  return axios.request({
    url: baseURL + '/account/image-captcha',
    method: 'get'
  })
}

export function verifyCaptcha(data) {
  return axios.request({
    url: baseURL + '/account/sms/image-captcha',
    method: 'post',
    data
  })
}

export function login(data) {
  const dist_code = sessionStorage.getItem('deepsound_dist')
  return axios.request({
    url: baseURL + '/account/virtual-human-token',
    method: 'post',
    data,
    headers: {
      'X-Dist-Code': dist_code || ''
    }
  })
}

export function loginInfo(user_id) {
  return axios.request({
    url: '/v1.0/virtual-human' + `/user-profile/${user_id}`,
    method: 'get',
    headers: {
      'Service-Name': 'virtual-human-service'
    }
  })
}

export function brandLogo() {
  // /v1.0/virtual-human/brand/logo
  return axios.request({
    url: `/v1.0/virtual-human/brand/logo`,
    method: 'get',
    headers: {
      'Service-Name': 'virtual-human-service'
    }
  })
}

// /v1.0/virtual-human/activity

export function dhumanAct() {
  return axios.request({
    url: `/v1.0/virtual-human/activity`,
    method: 'get',
    headers: {
      'Service-Name': 'virtual-human-service'
    }
  })
}