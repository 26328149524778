import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import cooperation from "../views/cooperation.vue"
import cooperation_result from '../views/cooperation_result'
import dhuman_intro from '../views/dhuman_intro'
import pay from '../views/pay'
import index from '../views/Home/index.vue'
import login from '../views/login.vue'
import agreement from '../views/agreement.vue'
import regist_success from '../views/regist_success.vue'
import activity from '../views/activity'
Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: agreement
  },
  {
    path: '/video',
    name: 'video',
    component: HomeView
  },
  {
    path: "/",
    name: "index",
    component: index,
  },
  {
    path: "/cooperation",
    name: "cooperation",
    component: cooperation,
  },
  {
    path: "/cooperation_result",
    name: "cooperation_result",
    component: cooperation_result,
  },
  {
    path: "/dhuman_intro",
    name: "dhuman_intro",
    component: dhuman_intro
  },
  {
    path: "/pay",
    name: "pay",
    component: pay
  },
  {
    path: '/regist_success',
    name: 'regist_success',
    component: regist_success
  },
  {
    path: '/activity',
    name: 'activity',
    component: activity
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
