<template>
  <div class="__video-swiper" ref="videoSwiper">
    <div class="__h5-video-warp">
      <video
        @play="handlePlay"
        @ended="handleEnded"
        @pause="handlePause"
        ref="videoPlayer"
        class="__page-video"
        :src="
          isOEM ? videoItems[2].url_oem || videoItems[2].url : videoItems[2].url
        "
        :poster="
          isOEM
            ? videoItems[2].poster_oem || videoItems[2].poster
            : videoItems[2].poster
        "
        @click="videoClick"
        :class="{ __moving: arrow }"
        playsinline="true"
        webkit-playsinline="true"
        x5-video-player-type="h5"
        x5-video-player-fullscreen="true"
      ></video>
    </div>
    <div
      class="__video-btn"
      v-show="['stop', 'pause', 'ended'].includes(videoStatus)"
      @click="videoClick"
      :class="{ __moving: arrow }"
    >
      <img class="__btn-img" src="~@/assets/images/video_play.png" alt="" />
    </div>
    <!-- <Player ref="videoPlayer" :class="{'__moving': arrow}" class="__page-video" /> -->
    <div class="__video-boxs-container">
      <template v-for="(item, index) in videoItems">
        <div
          :key="'video' + item.url + index"
          class="__item"
          :class="[
            `__item-${item.index}`,
            (arrow === 'next' && item.index === 0) ||
            (arrow === 'prev' && item.index === 4)
              ? '__low'
              : '',
            isIOS() ? '__ios' : '',
          ]"
          :style="{ opacity: item.index === 2 && !moving ? 0 : 1 }"
        >
          <div class="__video-warp">
            <img
              style="
                width: 100%;
                height: calc(100%);
                z-index: 1;
                position: absolute;
                top: 0;
                left: 0;
              "
              :src="isOEM ? item.poster_oem || item.poster : item.poster"
              alt=""
            />
            <img
              class="__btn-img-img"
              src="~@/assets/images/video_play.png"
              alt=""
            />
          </div>
        </div>
      </template>
    </div>
    <button class="__next" @click="next()"></button>
    <button class="__prev" @click="prev()"></button>
    <div class="__font-warp">
      <div
        class="__font-item"
        @click="changeTab(index)"
        v-for="(item, index) in videoNameList"
        :key="'name' + item"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
const CDN_BASH_PATH =
  'https://cdn.img.deepsound.cn/config/dhuman/h5/video_intro';
import XGvideoPlayer from '@/components/XGvideoPlayer.vue';
import Player from '@/components/Player.vue';
import _ from 'lodash';
import { isIOS } from '@/utils';
import { mapGetters } from 'vuex';
export default {
  components: {
    XGvideoPlayer,
    Player,
  },
  data() {
    return {
      isIOS,
      options: {
        slidesPerView: 2,
        centeredSlides: true,
        grabCursor: true,
        loop: true,
        autoplay: {
          disableOnInteraction: false,
          delay: 30000,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      videoItems: [
        {
          name: '知识口播',
          url: CDN_BASH_PATH + '/video/zhishikoubo.mp4',
          poster: CDN_BASH_PATH + '/poster/zhishikoubo.png',
          key: Math.random() + '',
          index: 0,
        },
        {
          name: '金融保险',
          url: CDN_BASH_PATH + '/video/jinrongbaoxian.mp4',
          poster: CDN_BASH_PATH + '/poster/jinrongbaoxian.png',
          key: Math.random() + '',
          index: 1,
        },
        {
          name: '企业宣传',
          url: CDN_BASH_PATH + '/video/qiyexuanchuan.mp4',
          url_oem: CDN_BASH_PATH + '/video/qiyexuanchuan_oem.mp4',
          poster: CDN_BASH_PATH + '/poster/qiyexuanchuan.png',
          poster_oem: CDN_BASH_PATH + '/poster/qiyexuanchuan_oem.png',
          key: Math.random() + '',
          index: 2,
        },
        {
          name: '直播带货',
          url: CDN_BASH_PATH + '/video/zhibodaihuo.mp4',
          poster: CDN_BASH_PATH + '/poster/zhibodaihuo.png',
          key: Math.random() + '',
          index: 3,
        },
        {
          name: '新闻播报',
          url: CDN_BASH_PATH + '/video/xinwenzixun.mp4',
          poster: CDN_BASH_PATH + '/poster/xinwenzixun.png',
          key: Math.random() + '',
          index: 4,
        },
      ],
      videoStatus: 'stop',
      moving: false,
      arrow: '',
      pageVideo: {
        url: '',
        poster: '',
      },
    };
  },
  computed: {
    ...mapGetters(['isOEM']),
    videoNameList() {
      console.log(this.videoItems);
      const list = _.cloneDeep(this.videoItems);
      list.sort((a, b) => {
        return a.index - b.index;
      });
      const nameList = list.map((item) => item.name);
      return nameList;
    },
  },
  watch: {
    isOEM() {
      if (this.isOEM) {
        this.videoItems[2].url = this.videoItems[2].url_oem;
        this.videoItems[2].poster = this.videoItems[2].poster_oem;
      }
    },
  },
  mounted() {
    if (this.isOEM) {
      this.videoItems[2].url = this.videoItems[2].url_oem;
      this.videoItems[2].poster = this.videoItems[2].poster_oem;
    }
    let startX = 0;
    let startY = 0;
    this.$refs['videoSwiper'].addEventListener(
      'touchstart',
      (event) => {
        startX = event.touches[0].pageX;
        startY = event.touches[0].pageY;
      },
      { passive: false },
    );
    this.$refs['videoSwiper'].addEventListener(
      'touchend',
      (event) => {
        const spanX = event.changedTouches[0].pageX - startX;
        const spanY = event.changedTouches[0].pageY - startY;
        if (Math.abs(spanX) > Math.abs(spanY)) {
          //认定为水平方向滑动
          if (spanX > 30) {
            //向右
            this.next();
          } else if (spanX < -30) {
            //向左
            this.prev();
          }
        }
      },
      { passive: false },
    );
  },
  methods: {
    // 获取当前视频
    getActiveVideo() {
      let targetVideo = null;
      this.videoItems.forEach((item) => {
        if (item.index === 2) {
          targetVideo = item;
        }
      });
      return targetVideo;
    },
    // 设置当前视频
    setCurrentVideo() {
      const videoInfo = this.getActiveVideo();
      const video = this.$refs.videoPlayer;
      video.setAttribute('src', videoInfo.url);
      video.setAttribute('poster', videoInfo.poster);
      video.pause();
    },
    changeTab(index) {
      if (index === 0) {
        this.next();
        setTimeout(() => {
          this.next(true);
        }, 650);
      }
      if (index === 1) {
        this.next();
      }

      if (index === 3) {
        this.prev();
      }

      if (index === 4) {
        this.prev();
        setTimeout(() => {
          this.prev(true);
        }, 650);
      }
    },
    pauseAllVideo() {
      this.$refs.videoPlayer.pause();
      this.moving = true;
      setTimeout(() => {
        this.moving = false;
      }, 650);
    },
    prev(isYingyaoqie) {
      if (this.moving && !isYingyaoqie) {
        return false;
      }
      this.arrow = 'prev';
      this.videoItems.forEach((item) => {
        const index = (((item.index - 1) % 5) + 5) % 5;
        item.index = index;
      });
      this.pauseAllVideo();
      setTimeout(() => {
        this.arrow = '';
      }, 650);
      this.setCurrentVideo();
      this.videoStatus = 'pause';
    },
    next(isYingyaoqie) {
      if (this.moving && !isYingyaoqie) {
        return false;
      }
      this.arrow = 'next';
      this.videoItems.forEach((item) => {
        const index = (((item.index + 1) % 5) + 5) % 5;
        item.index = index;
      });
      this.pauseAllVideo();
      setTimeout(() => {
        this.arrow = '';
      }, 650);
      this.setCurrentVideo();
      this.videoStatus = 'pause';
    },
    playVideo(item) {
      const video = this.$refs.pageVideo;
      video.src = this.isOEM ? item.url_oem || item.url : item.url;
      video.play();
    },
    handlePlay() {
      this.videoStatus = 'play';
    },
    handleEnded() {
      this.videoStatus = 'ended';
    },
    handlePause() {
      this.videoStatus = 'pause';
    },
    videoClick() {
      const video = this.$refs.videoPlayer;
      if (this.videoStatus === 'play') {
        video.pause();
      } else {
        video.play();
      }
    },
  },
};
</script>

<style scoped lang="stylus">
.__font-warp
  display flex
  padding 0rem .2rem 0 .2rem
  justify-content space-between
  align-items center
  .__font-item
    &:nth-child(1), &:nth-child(5)
      font-size: 10px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
    &:nth-child(2), &:nth-child(4)
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    &:nth-child(3)
      font-size: 14px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #177FFF;
.__video-swiper
  position relative
  overflow hidden
  transform-style: preserve-3d
  margin-top .2rem
  .__h5-video-warp
    position absolute
    width: 1.74rem;
    height: 3.12rem;
    z-index 101
    top 0
    left calc(50%)
    margin-left -0.87rem
    opacity 1
    top: .12rem
    object-fit cover
  .__video-btn
    position absolute
    width: 1.74rem;
    height: 3.12rem;
    z-index 102
    top 0
    left calc(50%)
    margin-left -0.87rem
    opacity 1
    top: .12rem
    display flex
    justify-content center
    align-items center
    &.__moving
      opacity 0
    .__btn-img
      width .52rem
      height .52rem
  .__page-video
    width 100%
    height 100%
    z-index 1001
    &.__moving
      opacity 0
  .__video-boxs-container
    perspective: 1200px
    height 3.36rem
  .__next
    width .32rem
    height .32rem
    position absolute
    top 50%
    margin-top -16px
    left .32rem
    z-index 101
    background-color #fff;
    box-shadow: 0px 4px 8px 0px rgba(21,30,73,0.1);
    border none
    border-radius 50%
    background-image url('@/assets/images/components/arrow-left.png')
    background-size .16rem .16rem
    background-repeat no-repeat
    background-position center center
  .__prev
    width .32rem
    height .32rem
    position absolute
    top 50%
    margin-top -16px
    right .32rem
    z-index 101
    background-color #fff;
    box-shadow: 0px 4px 8px 0px rgba(21,30,73,0.1);
    border none
    border-radius 50%
    background-image url('@/assets/images/components/arrow-right.png')
    background-size .16rem .16rem
    background-repeat no-repeat
    background-position center center
  .__item
    width: 1.89rem;
    height: 3.36rem;
    position absolute
    transition transform ease-in-out .65s
    &.__low
      z-index 0
      &.__ios
        opacity 0
    .__video-warp
      width 100%
      height 100%
      position relative
      display flex
      justify-content center
      align-items center
      .__btn-img-img
        position absolute
        z-index 101
        width .56rem
        height .56rem
      .__video
        width 100%
        height 100%
  .__item-0
    transform: translate3d(-0.42rem, 0px, -1rem) rotateX(0deg) rotateY(45deg) scale(.7);
    z-index: 1;
  .__item-1
    transform: translate3d(0rem, 0px, -1rem) rotateX(0deg) rotateY(45deg) scale(.8);
    z-index: 2;
  .__item-2
    transform: translate3d(0.93rem, 0px, -1rem) rotateX(0deg) rotateY(0deg) scale(1);
    z-index: 3;
  .__item-3
    transform: translate3d(1.87rem, 0px, -1rem) rotateX(0deg) rotateY(-45deg) scale(.8);
    z-index: 2;
  .__item-4
    transform: translate3d(2.29rem, 0px, -1rem) rotateX(0deg) rotateY(-45deg) scale(.7);
    z-index: 1 ;
</style>
