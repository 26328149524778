var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"videoSwiper",staticClass:"__video-swiper"},[_c('div',{staticClass:"__h5-video-warp"},[_c('video',{ref:"videoPlayer",staticClass:"__page-video",class:{ __moving: _vm.arrow },attrs:{"src":_vm.isOEM ? _vm.videoItems[2].url_oem || _vm.videoItems[2].url : _vm.videoItems[2].url,"poster":_vm.isOEM
          ? _vm.videoItems[2].poster_oem || _vm.videoItems[2].poster
          : _vm.videoItems[2].poster,"playsinline":"true","webkit-playsinline":"true","x5-video-player-type":"h5","x5-video-player-fullscreen":"true"},on:{"play":_vm.handlePlay,"ended":_vm.handleEnded,"pause":_vm.handlePause,"click":_vm.videoClick}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(['stop', 'pause', 'ended'].includes(_vm.videoStatus)),expression:"['stop', 'pause', 'ended'].includes(videoStatus)"}],staticClass:"__video-btn",class:{ __moving: _vm.arrow },on:{"click":_vm.videoClick}},[_c('img',{staticClass:"__btn-img",attrs:{"src":require("@/assets/images/video_play.png"),"alt":""}})]),_c('div',{staticClass:"__video-boxs-container"},[_vm._l((_vm.videoItems),function(item,index){return [_c('div',{key:'video' + item.url + index,staticClass:"__item",class:[
          `__item-${item.index}`,
          (_vm.arrow === 'next' && item.index === 0) ||
          (_vm.arrow === 'prev' && item.index === 4)
            ? '__low'
            : '',
          _vm.isIOS() ? '__ios' : '',
        ],style:({ opacity: item.index === 2 && !_vm.moving ? 0 : 1 })},[_c('div',{staticClass:"__video-warp"},[_c('img',{staticStyle:{"width":"100%","height":"calc(100%)","z-index":"1","position":"absolute","top":"0","left":"0"},attrs:{"src":_vm.isOEM ? item.poster_oem || item.poster : item.poster,"alt":""}}),_c('img',{staticClass:"__btn-img-img",attrs:{"src":require("@/assets/images/video_play.png"),"alt":""}})])])]})],2),_c('button',{staticClass:"__next",on:{"click":function($event){return _vm.next()}}}),_c('button',{staticClass:"__prev",on:{"click":function($event){return _vm.prev()}}}),_c('div',{staticClass:"__font-warp"},_vm._l((_vm.videoNameList),function(item,index){return _c('div',{key:'name' + item,staticClass:"__font-item",on:{"click":function($event){return _vm.changeTab(index)}}},[_vm._v(" "+_vm._s(item)+" ")])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }