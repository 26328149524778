<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as api from '@/api';
export default {
  name: 'App',
  created() {
    // 判断下屏幕是否大于 600
    const DESIGN_WIDTH = 375;
    let clientWidth = document.body.clientWidth;
    let htmlNodeSize = 100;
    if (clientWidth > 600) {
      clientWidth = 600;
    }
    htmlNodeSize = (clientWidth / DESIGN_WIDTH) * 100;
    document.documentElement.style.fontSize = htmlNodeSize + 'px';
  },
  computed: {
    ...mapGetters(['isOEM', 'brand']),
  },
  mounted() {
    this.$store.commit('setOEM', false);
    api.brandLogo().then((res) => {
      const data = res.data.data;
      if (data) {
        const brand = data.brand;
        this.$store.commit('setOEM', brand.is_show_ipc ? false : true);
        this.$store.commit('setBrand', brand);
        if(this.isOEM){
          document.title = this.brand.name
        }else {
          document.title = "Dhuman数字人"
        }
      }
    });
    api.dhumanAct().then(res => {
      this.$store.commit('setAct', !!res.data.data.status);
    })
  },
};
</script>

<style lang="stylus">
*{
  padding: 0;
  margin: 0;
  box-sizing border-box;
}
div, span{
  font-size: 0.14rem
}
body, html{
  background: #ffffff;
}
</style>
