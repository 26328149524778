import { render, staticRenderFns } from "./cooperation.vue?vue&type=template&id=8dcf0f2a&scoped=true&"
import script from "./cooperation.vue?vue&type=script&lang=js&"
export * from "./cooperation.vue?vue&type=script&lang=js&"
import style0 from "./cooperation.vue?vue&type=style&index=0&id=8dcf0f2a&prod&scoped=true&lang=stylus&"
import style1 from "./cooperation.vue?vue&type=style&index=1&id=8dcf0f2a&prod&lang=stylus&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8dcf0f2a",
  null
  
)

export default component.exports