<template>
  <div class="__pay">
    <div class="__title-warp">
      <img
        class="__title-warp-image"
        src="~@/assets/images/1.8.0/pay_title.png"
        alt=""
      />
    </div>
    <div class="__coop-warp">
      <img
        v-for="(item, index) in coops"
        :src="item"
        :key="index"
        alt=""
        class="__coop-item-image"
      />
    </div>
    <div class="__account-number">
      <span class="__notice">累计用户量</span>
      <span class="__num">20,000,000+</span>
    </div>
    <div class="__swiper-warp" v-show="!isOpen">
      <swiper ref="mySwiper" class="swiper" :options="options">
        <swiper-slide>
          <img class="__sw-image" :src="sw1" alt="" />
          <p class="__sw-text">
            不到一顿火锅的价钱 <br />
            即可永久留住自己/他人的声音
          </p>
        </swiper-slide>
        <swiper-slide>
          <img class="__sw-image" :src="sw2" alt="" />
          <p class="__sw-text">小程序中录制90秒，跟读20句话即可</p>
        </swiper-slide>
        <swiper-slide>
          <img class="__sw-image" :src="sw3" alt="" />
          <p class="__sw-text">
            用克隆声音合成视频<br />
            直播带货、资讯播报、送祝福…
          </p>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div class="__wx-but-container" id="__wx-but-container"></div>
    <div class="__phone-warp">
      <template v-if="!phone">
        <div class="__phone-input">
          <div @click="isShowPicker = true" class="__area-code">
            {{ area_code.text }}
            <van-icon class="area-code-icon" color="#177fff" name="play" />
          </div>
          <input
            type="number"
            oninput="if(value.length>11)value=value.slice(0,11)"
            v-model="inputPhone"
            maxlength="11"
            placeholder="输入手机号"
            class="__phone-input-inner"
            @input="handleTextBlur"
          />
        </div>
      </template>
      <template v-if="phone">
        <div class="__phone-notice">当前账号:{{ phone }}</div>
      </template>
      <div class="__pay-button" v-show="this.order_no" :key="'phone-warp' + viewKey">
        <wx-open-launch-weapp id="launch-btn">
          <script type="text/wxtag-template">
            <style>.btn {height: 44px;text-align: center;font-size: 18px;font-weight:bold;line-height: 44px;width: 100%;color: #fff;}</style>
              <div class="btn">打开测试小程序</div>
          </script>
        </wx-open-launch-weapp>
      </div>
      <div @click="creaOrder" class="__pay-button" v-if="!this.order_no">
        <div class="__taocan" v-show="pack.id">
          <div class="__shoudan" v-show="pack.is_first_discount === 1">
            首单
          </div>
          <div class="__price">￥{{ pack.price }}</div>
          <div class="__o-price">￥{{ pack.original_price }}</div>
        </div>
        微信支付
      </div>
    </div>
    <van-popup v-model="isShowPicker" round position="bottom">
      <van-picker
        title=""
        :columns="columns"
        @cancel="isShowPicker = false"
        @confirm="onConfirm"
        show-toolbar
      />
    </van-popup>
  </div>
</template>
<script>
const coop1 = require('@/assets/images/1.8.0/coop1.png');
const coop2 = require('@/assets/images/1.8.0/coop2.png');
const coop3 = require('@/assets/images/1.8.0/coop3.png');
const coop4 = require('@/assets/images/1.8.0/coop4.png');
const coop5 = require('@/assets/images/1.8.0/coop5.png');
const sw1 = require('@/assets/images/1.8.0/sw1.png');
const sw2 = require('@/assets/images/1.8.0/sw2.png');
const sw3 = require('@/assets/images/1.8.0/sw3.png');
const APP_ENV = 'release'
import { getQueryString, isAndroid, isIOS } from '@/utils';
import axios from 'axios';
export default {
  data() {
    return {
      sw1,
      sw2,
      sw3,
      coops: [coop1, coop2, coop3, coop4, coop5],
      options: {
        loop: true,
        autoplay: {
          disableOnInteraction: false,
          delay: 3000,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      area_code: { text: '+86', value: '86' },
      phone: '',
      inputPhone: '',
      code: '',
      columns: [
        { text: '+86', value: '86' },
        { text: '+852', value: '852' },
        { text: '+853', value: '853' },
        { text: '+886', value: '886' },
      ],
      isShowPicker: false,
      order_no: '',
      pack: {},
      openId: '',
      isOpen: false,
      viewKey: 0,
    };
  },
  async mounted() {
    const _this = this;
    if (isAndroid()) {
      const innerHeight = window.innerHeight;
      window.addEventListener('resize', () => {
        const newInnerHeight = window.innerHeight;
        if (innerHeight > newInnerHeight) {
          // 键盘弹出事件处理
          _this.isOpen = true;
        } else {
          // 键盘收起事件处理
          _this.isOpen = false;
        }
      });
    } else if (isIOS()) {
      window.addEventListener('focusin', () => {
        // 键盘弹出事件处理
        // _this.isOpen = true;
      });
      window.addEventListener('focusout', () => {
        // 键盘收起事件处理
        // _this.isOpen = false;
      });
    }

    //如果需要移除监听事件可以使用removeEventListener，但如果监听事件中使用的函数是匿名函数，可能移除事件没有任何效果，可以将监听事件执行的函数，提取出来，使用函数名来指定例如:    window.addEventListener('focusout', focusoutFunc);    function focusoutFunc (){       /*.....*/    }        window.removeEventListener('focusout', focusoutFunc);  //移除事件

    document.addEventListener('WeixinOpenTagsError', function (e) {
      console.error(e.detail.errMsg);
    });
    const code = getQueryString('code');
    const state = getQueryString('state');
    if (state) {
      this.area_code = ((val) => {
        let code = {};
        this.columns.forEach((item, index) => {
          if (val === item.value) {
            code = item;
          }
        });
        return code;
      })(state.split('-')[0]);
      this.phone = state.split('-')[1];
    }
    this.code = code;
    const signtrueInfo = await this.getSigntrue(window.location.href);
    if (signtrueInfo) {
      wx.config({
        debug: false,
        appId: signtrueInfo.appId, // 必填，公众号的唯一标识
        timestamp: signtrueInfo.timestamp, // 必填，生成签名的时间戳
        nonceStr: signtrueInfo.nonceStr, // 必填，生成签名的随机串
        signature: signtrueInfo.signature, // 必填，签名
        jsApiList: ['chooseImage', 'chooseWXPay'],
        // 必填，需要使用的 JS 接口列表
        openTagList: ['wx-open-launch-weapp'],
      });
      wx.ready(() => {
        this.viewKey++;
        this.$nextTick(() => {
          const btn = document.getElementById('launch-btn');
          btn.setAttribute('username', 'gh_f016424a0e1e');
          btn.setAttribute(
            'path',
            'pages/clone/recordSelect' + '?state_phone=' + this.phone,
          );
          btn.setAttribute('env-version', APP_ENV);
          btn.addEventListener('launch', function (e) {});
          btn.addEventListener('error', function (e) {});
        });
      });
      wx.error((err) => {
        console.log(err);
      });
    }
    const openId = await this.getOpenid(this.code);
    if(openId){
      sessionStorage.setItem('openId', openId);
    }
    if (this.phone) {
      this.getPhoneOrder(this.phone, this.area_code.value);
    }
    this.openId = openId;
  },
  methods: {
    onConfirm(item) {
      this.isShowPicker = false;
      this.area_code = item;
    },
    onCancel() {},
    onChange(val) {
      console.log(val);
    },
    handleLaunchFn(e) {},
    handleErrorFn(e) {},
    // 获取openid
    async getOpenid(code) {
      if (sessionStorage.getItem('openId')) {
        return sessionStorage.getItem('openId');
      }
      const params = {
        code,
      };

      return axios
        .request({
          url: '/v1.0/virtual-human/page/auth',
          method: 'get',
          params,
        })
        .then((res) => {
          const openId = res.data.data.openId;
          return openId;
        })
        .catch((err) => {
          return null;
        });
    },
    // 获取签名
    getSigntrue(url) {
      const params = {
        url,
      };
      return axios
        .request({
          url: '/v1.0/virtual-human/page/jssign',
          method: 'get',
          params,
        })
        .then((res) => {
          return res.data.data;
        })
        .catch((err) => {
          return null;
        });
    },
    // 获取手机号订单
    getPhoneOrder(phone, area_code) {
      // /v1.0/virtual-human/clone-voice/page/package
      const params = {
        phone,
        area_code,
      };
      return axios
        .request({
          url: ' /v1.0/virtual-human/clone-voice/page/package',
          method: 'get',
          params,
        })
        .then((res) => {
          const data = res.data.data;
          const order_no = data.order_no;
          const pack = data.pack;
          this.order_no = order_no;
          this.pack = pack;
          return true;
        })
        .catch((err) => {
          this.pack = {};
          this.order_no = '';
          return null;
        });
    },
    handleTextBlur() {
      this.pack = {};
      this.order_no = '';
      if (this.inputPhone.length > 7) {
        this.creaOrder(true);
      }
    },
    async creaOrder(isCheck) {
      this.pack = {};
      this.order_no = '';
      if (this.inputPhone.length < 8 && !this.phone) {
        this.$notify('请输入正确的手机号');
        return false;
      }
      if (!this.pack.id) {
        await this.getPhoneOrder(
          this.phone || this.inputPhone,
          this.area_code.value,
        );
        const _this = this
        _this.viewKey++;
        _this.$nextTick(() => {
          const btn = document.getElementById('launch-btn');
          btn.setAttribute('username', 'gh_f016424a0e1e');
          btn.setAttribute(
            'path',
            'pages/clone/recordSelect' + '?state_phone=' + _this.inputPhone,
          );
          btn.setAttribute('env-version', APP_ENV);
        });
      }
      if (!this.pack.id) {
        if (isCheck !== true) {
          this.$notify('账号不存在');
        }
        return false;
      } else {
      }
      const order_id = await axios
        .request({
          url: '/v1.0/virtual-human/page/order',
          method: 'post',
          data: {
            area_code: this.area_code.value,
            phone: this.phone || this.inputPhone,
            package_id: this.pack.id,
          },
        })
        .then((res) => {
          return res.data.data.id;
        })
        .catch((err) => {
          return null;
        });
      if (this.order_no || !order_id) {
        return false;
      }
      if (isCheck === true) {
        return false;
      }
      const wxOrder = await axios
        .request({
          method: 'post',
          url: '/v1.0/virtual-human/wx/mp/pay',
          data: {
            order_id,
            open_id: this.openId,
          },
        })
        .then((res) => {
          return res.data.data.pay_param;
        })
        .catch((err) => {
          console.log(err);
          return null;
        });
      const _this = this;

      wx.chooseWXPay({
        timestamp: wxOrder.timeStamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
        nonceStr: wxOrder.nonceStr, // 支付签名随机串，不长于 32 位
        package: wxOrder.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType: wxOrder.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
        paySign: wxOrder.paySign, // 支付签名
        success: function (res) {
          // 支付成功后的回调函数
          if (getQueryString('state')) {
            window.history.go(0);
          } else {
            _this.getPhoneOrder(_this.inputPhone, _this.area_code.value);
            _this.viewKey++;
            _this.$nextTick(() => {
              const btn = document.getElementById('launch-btn');
              btn.setAttribute('username', 'gh_f016424a0e1e');
              btn.setAttribute(
                'path',
                'pages/clone/recordSelect' + '?state_phone=' + _this.inputPhone,
              );
              btn.setAttribute('env-version', APP_ENV);
            });
          }
        },
      });
    },
  },
};
</script>
<style scoped lang="stylus">
>>>.swiper-pagination-bullet  
  background: #DFEFFF !important
  opacity 1
>>>.swiper-pagination-bullet-active
  background: #177fff !important
.__wx-but-container
  position absolute
  z-index 1
.__pay
  width 100vw
  height 100vh
  background red
  position relative
  background: linear-gradient(234deg, #DDECFF 0%, #FDFEFF 70%)
  padding .2rem .3rem 0 .3rem
  max-width 600px
  .__title-warp
    text-align center
    .__title-warp-image
      width 2.47rem
  .__coop-warp
    display flex
    justify-content space-between
    margin-top .34rem
    .__coop-item-image
      width .52rem
      height .52rem
      box-shadow 0 4px 8px 0px rgba(30, 91, 212, 0.19)
      border-radius .1rem
  .__account-number
    margin-top .12rem
    text-align center
    display flex
    align-items flex-end
    justify-content  center
    .__notice
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #082A53;
    .__num
      font-size: 17px;
      font-family: SF Pro Text-Heavy, SF Pro Text;
      font-weight: bolder;
      color: #2888FF
      padding-left 4px
      display inline-block
      margin-bottom -1px
  .__swiper-warp
    margin-top .26rem
    .swiper
      height 2.66rem
      .swiper-slide
        display flex
        flex-direction column
        align-items center
      .__sw-image
        height 2rem
        width auto
        max-width 80%
      .__sw-text
        font-size: .14rem;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #0864CF;
        line-height: 19px
        text-align center
  .__phone-warp
    position absolute
    // margin-top .25rem
    width 100%
    padding 0 30px
    left 0
    padding-top 20px
    z-index 1
    bottom 0
    padding-bottom 32px
    padding-bottom calc(32px + constant(safe-area-inset-bottom))
    padding-bottom calc(32px + env(safe-area-inset-bottom))
    .__phone-notice
      height: 24px;
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 19px
      margin-bottom 0.62rem
      text-align center
    .__phone-input
      height: .44rem;
      background: #E5F1FF;
      width 100%;
      border-radius 1rem
      margin-bottom 0.62rem
      text-align center
      position relative
      display flex
      align-items center
      .__area-code
        height: .44rem;
        line-height .44rem
        color #177fff
        padding-left .44rem
        font-size 16px
      .area-code-icon
        transform rotate(90deg)
        font-size 12px
        padding-right 3px
      .__phone-input-inner
        height: .44rem;
        background: #E5F1FF;
        width 50%;
        border-radius 1rem
        outline none
        border none
        color #333
        font-size .16rem
        padding-left .16rem
        &::placeholder
          color: #177FFF;
    .__pay-button
      height: .44rem
      background: linear-gradient(90deg, #32B4FF 0%, #1881FF 100%);
      border-radius: 1rem
      font-size: .18rem
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold
      color #fff
      text-align center
      line-height .44rem
      display flex
      justify-content center
      align-items center
      position relative
      #launch-btn
        width 100% 
        height 100%
        display flex
        justify-content center
        align-items center
      .__taocan
        position absolute
        width 100%
        height 40px
        top -40px
        display flex
        align-items center
        justify-content center
        .__shoudan
          height: 17px;
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 16px
        .__price
          height: 32px;
          font-size: 23px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #F5584C;
          line-height: 27px
        .__o-price
          height: 17px;
          font-size: 11px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #999999;
          line-height: 16px
          text-decoration: line-through
</style>
