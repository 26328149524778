<template>
  <div class="__index" :style="{ paddingBottom: isOEM ? '0' : '1.02rem' }">
    <Section1 />
    <Section2 />
    <Section3 />
    <div class="__bottom" v-if="!isOEM">
      <img
        @click="showWeixin"
        class="__bottom-img"
        src="~@/assets/images/1.10.0/bottom_icon_weixin.png"
        alt=""
        v-if="oem_wx_qrcode_urls.length"
      />
      <div style="min-width: 0.16rem" v-if="oem_wx_qrcode_urls.length"></div>
      <div class="__bottom-btn" @click="$router.push({ path: '/cooperation' })">
        <img
          class="__bottom-img"
          src="~@/assets/images/1.10.0/bottom_icon_xin.png"
          alt=""
        />
        <span class="__btn-text">合作留言</span>
      </div>
      <div style="min-width: 0.16rem" v-if="oem_phones.length"></div>
      <div
        class="__bottom-btn __deep"
        @click="makePhoneCall"
        v-if="oem_phones.length"
      >
        <img
          class="__bottom-img"
          src="~@/assets/images/1.10.0/bottom_icon_tel.png"
          alt=""
        />
        <span class="__btn-text">电话联系</span>
      </div>
    </div>
    <div class="__bottom" v-if="isOEM">
      <img
        @click="showWeixin"
        class="__bottom-img"
        src="~@/assets/images/1.10.0/bottom_icon_weixin.png"
        alt=""
        v-if="oem_wx_qrcode_urls.length"
      />
      <div style="min-width: 0.16rem" v-if="oem_wx_qrcode_urls.length"></div>
      <div class="__bottom-btn" @click="$router.push({ path: '/login' })">
        <img class="__bottom-img" src="~@/assets/images/feiji.png" alt="" />
        <span class="__btn-text">立即体验</span>
      </div>
      <div style="min-width: 0.16rem" v-if="oem_phones.length"></div>
      <div
        class="__bottom-btn __deep"
        @click="makePhoneCall"
        v-if="oem_phones.length"
      >
        <img
          class="__bottom-img"
          src="~@/assets/images/1.10.0/bottom_icon_tel.png"
          alt=""
        />
        <span class="__btn-text">电话联系</span>
      </div>
    </div>
    <div class="__weixin" v-show="isShowWeixin">
      <div class="__weixin-container">
        <div class="__header-bg"></div>
        <div @click="closeWeixin" class="__close"></div>
        <div class="__content">
          <div class="__code-warp">
            <img
              v-for="(item, index) in oem_wx_qrcode_urls"
              :key="`${item}_${index}`"
              class="__code"
              :src="item"
              alt=""
            />
          </div>
          <div class="__notice">添加商务微信获取更多信息</div>
        </div>
      </div>
    </div>
    <!-- 拨打电话 -->
    <PhoneCall ref="PhoneCall" />
    <div class="__act-mask" v-show="showAct && act">
      <img
        @click="toActivity"
        class="__image"
        src="@/assets/images/act.png"
        alt=""
      />
      <div @click="jinri = !jinri" class="__checkbox-warp">
        <div class="__checkbox" :class="{ active: jinri }"></div>
        <span>今日不再提示</span>
      </div>
      <img
        @click="clickClose"
        class="__guanbi"
        src="@/assets/images/guanbi.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import Section1 from './Section1.vue';
import Section2 from './Section2.vue';
import Section3 from './Section3.vue';
import { mapGetters } from 'vuex';
import PhoneCall from '@/views/components/PhoneCall.vue';
import OemContact from '@/mixins/OemContact';
import moment from 'moment'
export default {
  components: {
    Section1,
    Section2,
    Section3,
    PhoneCall,
    OemContact,
  },
  mixins: [OemContact],
  data() {
    let showAct = false
    const jinri = localStorage.getItem('jinri')
    if(jinri){
      if(new Date().getTime() > new Date(jinri).getTime()){
        showAct = true
      }else {
        showAct = false
      }
    }else {
      showAct = true
    }
    return {
      isShowWeixin: false,
      showAct: showAct,
      jinri: false,
    };
  },
  computed: {
    ...mapGetters(['isOEM', 'act']),
  },
  mounted() {
    sessionStorage.setItem('deepsound_dist', this.$route.query.dist);
  },
  methods: {
    showWeixin() {
      this.isShowWeixin = true;
      document.body.style.overflow = 'hidden';
    },
    closeWeixin() {
      this.isShowWeixin = false;
      document.body.style.overflow = 'auto';
    },
    makePhoneCall() {
      this.$refs.PhoneCall.call();
    },
    toActivity() {
      if (process.env.NODE_ENV === 'development') {
        window.open('https://www.deepsound.cn:8443/activity/001');
      } else {
        window.open('https://www.deepsound.cn/activity/001');
      }
    },
    clickClose(){
      if(this.jinri){
        localStorage.setItem('jinri', moment(new Date()).format('YYYY-MM-DD 23:59:59'))
      }
      this.showAct = false
    }
  },
};
</script>

<style lang="stylus">
.__act-mask
  width 100vw
  height 100vh
  position fixed
  top 0
  left 0
  background-color rgba(0, 0, 0 , .39)
  z-index 11111
  display flex
  justify-content center
  align-items center
  flex-direction column
  .__image
    width calc(100vw - 110px)
    height auto
  .__guanbi
    margin-top 20px
    margin-bottom 100px
    width .3rem
    height .3rem
  .__checkbox-warp
    color #fff
    display flex
    align-items center
    font-size 12px
    margin-top 12px
  .__checkbox
    width 12px
    height 12px
    border: 1px solid #FFFFFF;
    position relative
    margin-right 4px
    &.active
      &:after
        content '' 
        position absolute 
        width 6px 
        height 6px 
        background-color #177fff
        top 2px
        left 2px
.__weixin
  position fixed
  top 0
  left 0
  width 100vw
  height 100vh
  z-index 1000001
  background-color rgba(0,0,0,0.3);
  display flex
  justify-content center
  align-items center
  .__weixin-container
    position relative
    width 2.8rem
    background: #FFFFFF;
    box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.2);
    border-radius: 10px 10px 10px 10px;
    .__header-bg
      height .88rem
      background: linear-gradient(180deg, rgba(23,127,255,0.2) 0%, rgba(217,217,217,0) 100%);
    .__content
      margin-top -0.28rem
      .__code-warp
        max-height calc(100vh - 1rem)
        overflow-y auto
        overflow-x hidden
        display flex
        flex-direction column
        align-items center
      .__code
        width 1.48rem
        height 1.48rem
        margin-bottom .2rem
      .__notice
        font-size: .14rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #666666;
        line-height: .22rem;
        text-align center
        padding-bottom .20rem
    .__close
      background-image url('@/assets/images/1.10.0/close.png')
      background-size cover
      width 24px
      height 24px
      position absolute
      right 8px
      top 8px
      z-index 11
.__bottom-oem
  position fixed
  bottom 0
  padding .1rem .16rem
  padding-bottom: calc(.12rem + constant(safe-area-inset-bottom)); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(.12rem + env(safe-area-inset-bottom)); /* 兼容 iOS >= 11.2 */
  height .96rem
  width 100%
  z-index 10001
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #FFFFFF 100%)
  .__bottom-oem-btn
    height: .6rem;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px 0px rgba(23,127,255,0.2);
    border-radius: .09rem;
    padding .08rem
    .__bottom-oem-btn-inner
      height: 100%;
      width 100%;
      background: #177FFF;
      border-radius: .08rem;
      display flex
      align-items center
      justify-content center
      .__text-oem
        color #fff
        font-size: .16rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
.__bottom
  position fixed
  bottom 0
  background #fff
  width 100%
  display flex
  align-items center
  padding 0.12rem .16rem .12rem .2rem
  padding-bottom: calc(.12rem + constant(safe-area-inset-bottom)); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(.12rem + env(safe-area-inset-bottom)); /* 兼容 iOS >= 11.2 */
  justify-content space-between
  box-shadow: 0px -4px 4px 0px rgba(0,0,0,0.02);
  z-index 10001
  max-width 600px
  .__bottom-img
    width .2rem
    height .2rem
  .__bottom-btn
    width: 1.4rem;
    height: .44rem;
    background: #E8F2FF;
    border-radius: .08rem;
    display flex
    align-items center
    justify-content center
    flex 1
    &.__deep
      background-color #177fff
      .__btn-text
        color #FFF;
    .__btn-text
      font-size: .16rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color #177FFF;
      line-height: .18rem;
      padding-left 3px
.__index
  position relative
  margin 0 auto
  max-width 600px
  padding-bottom 1.02rem
  .__sec-title
    display flex
    justify-content center
    padding-top 34px
    .__text
      height: 28px;
      font-size: 21px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #232323;
      line-height: 25px;
      position relative;
      &:before
        content ''
        position absolute
        width: 16px;
        height: 1px;
        background: #D9D9D9;
        top 14px
        left -32px
      &:after
        content ''
        position absolute
        width: 16px;
        height: 1px;
        background: #D9D9D9;
        top 14px
        right -32px
  .__sub-title
    height: 21px;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #3E78FF;
    line-height: 19px;
    letter-spacing: 1px
    text-align center;
    margin-top 6px;
  .__title-desc
    height: 18px;
    font-size: .14rem;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #7781B1;
    line-height: 16px;
    letter-spacing: 1px;
    text-align center
    margin-top .17rem
</style>
