// import '@/utils/BrowserInfo'
// let info = new BrowserInfo()
// const browserInfo = {
//   browser: info.browser,
//   device: info.device,
//   os: info.os,
//   osVersion: info.osVersion,
//   version: info.version
// }
function isMobile() {
  let flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  return flag;
}

const browserInfo = {
  device: isMobile() ? 'mobile' : 'PC'
}
export default class Drag {
  //构造函数
  constructor(el, l, ranges, moveCallBack, endCallBack) {
    this.el = el
    this.l = l
    // this.t = t
    if (ranges) {
      this.ranges = ranges
      this.minX = ranges.xs[0]
      this.maxX = ranges.xs[1]
      // this.minY = ranges.ys[0]
      // this.maxY = ranges.ys[1]
    }
    //鼠标摁下时的元素位置
    this.startOffset = {}
    //鼠标摁下时的鼠标位置
    this.startPoint = {}
    this.initPosition()
    let move = (e) => {
      e.stopPropagation()
      e.preventDefault()
      this.move(e, moveCallBack)
    }
    let end = (e) => {
      endCallBack && endCallBack(this.left)
      if (browserInfo.device === 'PC') {
        document.removeEventListener("mousemove", move)
        document.removeEventListener("mouseup", end)
      } else {
        document.removeEventListener("touchmove", move)
        document.removeEventListener("touchend", end)
      }
    }
    if (browserInfo.device === 'PC') {
      el.addEventListener("mousedown", (e) => {
        this.start(e)
        document.addEventListener("mousemove", move)
        document.addEventListener("mouseup", end)
      })
    } else {
      el.addEventListener('touchstart', e => {
        this.start(e)
        document.addEventListener("touchmove", move)
        document.addEventListener("touchend", end)
      })
    }
  }
  initPosition() {
    this.el.style.left = this.l + 'px'
  }
  //摁下时的处理函数
  start(e) {
    let {
      el
    } = this
    if (browserInfo.device === 'PC') {
      this.startOffset = {
        x: el.offsetLeft
      }
      this.startPoint = {
        x: e.clientX
      }
    } else {
      if (e.changedTouches.length === 1) {
        this.startOffset = {
          x: el.offsetLeft
        }
        this.startPoint = {
          x: e.changedTouches[0].clientX,
        }

      }
    }

  }
  //鼠标移动时的处理函数
  move(e, callback) {
    let {
      el,
      startOffset,
      startPoint
    } = this
    let newPoint = {}
    if (browserInfo.device === 'PC') {
      newPoint.x = e.clientX
      // y: e.clientY
    } else {
      newPoint.x = e.changedTouches[0].clientX
    }

    let dis = {
      x: newPoint.x - startPoint.x,
      // y: newPoint.y - startPoint.y,
    }
    let left = dis.x + startOffset.x
    // let top = dis.y + startOffset.y

    if (this.ranges) {
      if (this.minX <= left && left <= this.maxX) {
        el.style.left = left + "px"
        this.left = left
        callback && callback(left)
      }
      // if (this.minY <= top && top <= this.maxY) {
      //   el.style.top = top + "px"
      // }
    }
  }
}