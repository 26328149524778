<template>
  <div class="__section-3">
    <div class="__sec-title">
      <div class="__text">为科技企业、MCN、媒体机构</div>
    </div>
    <div class="__sub-title">提供丰富的方式合作</div>
    <div class="__title-desc">多次斩获大奖，累计用户20,000,000+</div>

    <swiper ref="mySwiper" class="swiper" :options="options">
      <swiper-slide
        class="__swiper-silde"
        v-for="item in demoItems"
        :key="item.name"
      >
        <img class="__image" :src="item.img" alt="" />
        <div>
          <img class="__icon" :src="item.icon" alt="" />
        </div>
        <div class="__text-warp">
          <div class="__title">{{ item.name }}</div>
          <p class="__desc-text">
            {{ isOEM ? item.desc_oem || item.desc : item.desc }}
          </p>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <div class="__slove" v-if="!isOEM">
      <div class="__slove-item" v-for="item in sloveItems" :key="item.title">
        <div
          class="__bg"
          :style="{
            backgroundImage: `url(${item.bg})`,
          }"
        >
          <div class="__slove-header">
            <img class="__icon" :src="item.icon" />
            <div class="__slove-title">{{ item.title }}</div>
          </div>
          <div class="__slove-desc">{{ item.desc}}</div>
        </div>
      </div>
    </div>
    <CoopPanel v-if="!isOEM" />
    <!-- 领取免费时长 -->
    <div class="__mianfei" v-if="!isOEM">
      <!-- <div class="__mianfei-title">150秒免费时长</div> -->
      <img class="__mianfei-title-img" src="~@/assets/images/1.10.0/p150.png" alt="">
      <div class="__new">
        <div class="__new-text">·新用户注册即可领取·</div>
      </div>
      <div class="__mianfei-btn-warp">
        <div class="__mianfei-btn" @click="$router.push({path: '/login'})">
          <div class="__mianfei-btn-text">领取免费时长</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const icon_xiaomi = require('@/assets/images/1.10.0/demo/icon_xiaomi.png');
const icon_cctv = require('@/assets/images/1.10.0/demo/icon_cctv.png');
const icon_58 = require('@/assets/images/1.10.0/demo/icon_58.png');
const img_xiaomi = require('@/assets/images/1.10.0/demo/img_xiaomi.png');
const img_cctv = require('@/assets/images/1.10.0/demo/img_cctv.png');
const img_58 = require('@/assets/images/1.10.0/demo/img_58.png');
import CoopPanel from './components/CoopPanel.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    CoopPanel
  },
  computed: {
    ...mapGetters(['isOEM'])
  },
  data() {
    return {
      options: {
        slidesPerView: 1.38,
        centeredSlides: true,
        spaceBetween: 12,
        grabCursor: true,
        loop: true,
        autoplay: {
          disableOnInteraction: false,
          delay: 3000,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      demoItems: [
        {
          name: '雷军在小米发布会上隆重介绍声音定制',
          desc: '1小米手机使用深声科技的声音克隆技术，用户录制20句短文本即可克隆恋人、父母、儿女的声音，替换手机助手小爱同学的声音，一经推出便深受用户喜爱。',
          desc_oem: '小米手机使用的声音克隆技术，用户录制20句短文本即可克隆恋人、父母、儿女的声音，替换手机助手小爱同学的声音，一经推出便深受用户喜爱。',
          icon: icon_xiaomi,
          img: img_xiaomi,
        },
        {
          name: 'CCTV综合频道《大国工匠》',
          desc: '深声科技为各大媒体机构、名人克隆数字人形象及声音',
          desc_oem: '为各大媒体机构、名人克隆数字人形象及声音',
          icon: icon_cctv,
          img: img_cctv,
        },
        {
          name: '杨幂、沈腾陪你过58神奇日',
          desc: '58同城的神奇日云发布会，使用深声科技声音克隆技术，上线了杨幂和沈腾的语音包。在活动主页输入名字，即可开启专属的全天贴心问候，引爆了粉丝们的参与热情。',
          desc_oem: '58同城的神奇日云发布会，使用声音克隆技术，上线了杨幂和沈腾的语音包。在活动主页输入名字，即可开启专属的全天贴心问候，引爆了粉丝们的参与热情。',
          icon: icon_58,
          img: img_58,
        },
      ],
      sloveItems: [
        {
          icon: require('@/assets/images/1.10.0/slove/i1.png'),
          bg: require('@/assets/images/1.10.0/slove/1.png'),
          title: '数字人平台D-Human',
          desc: '在平台编辑并生成视频，支持设置团队账号',
        },
        {
          icon: require('@/assets/images/1.10.0/slove/i2.png'),
          bg: require('@/assets/images/1.10.0/slove/2.png'),
          title: '数字人平台OEM定制',
          desc: '自定义平台的域名、企业名称、LOGO等，快速定制',
        },
        {
          icon: require('@/assets/images/1.10.0/slove/i3.png'),
          bg: require('@/assets/images/1.10.0/slove/3.png'),
          title: 'API接入',
          desc: '通过API接口获取数字人能力，赋能科技企业',
        },
        {
          icon: require('@/assets/images/1.10.0/slove/i4.png'),
          bg: require('@/assets/images/1.10.0/slove/4.png'),
          title: '渠道入驻',
          desc: '将数字人克隆到广大行业，获得丰厚回报',
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="stylus">
.__mianfei 
  background-image url('@/assets/images/1.10.0/mainfei.png')
  background-size cover
  padding .24rem 0 .2rem 0
  display flex
  flex-direction column
  align-items center
  .__mianfei-title 
    height: 33px;
    font-size: 24px;
    font-family: CKTKingKong-Bold, CKTKingKong;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 28px;
    letter-spacing: 2px; 
    text-align center
  .__mianfei-title-img 
    width 1.73rem
  .__new
    display flex
    justify-content center
    margin-top .05rem
    .__new-text
      height: 20px;
      font-size: 14px;
      font-family: Source Han Sans SC-Regular, Source Han Sans SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 16px;
      letter-spacing: 2px;
  .__mianfei-btn-warp
    display flex 
    justify-content center
    margin-top .12rem
    .__mianfei-btn
      width: 2.1rem;
      height: 0.44rem;
      line-height: 0.44rem;
      background: #FFFFFF;
      border-radius: .08rem;
      display flex 
      justify-content center 
      align-items center
      .__mianfei-btn-text 
        height: .18rem;
        font-size: .18rem;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #177FFF;
        line-height: .18rem;
>>>.swiper-container
  padding-bottom .20rem
>>>.swiper-container-horizontal
  &>.swiper-pagination-bullets
    display flex
    justify-content flex-end
    align-items center
    right 16%
    position absolute
    width auto
    bottom calc(.2rem + .1rem)
  .swiper-pagination-bullet
    width: 5px;
    height: 5px;
    margin 0 1px
    transition .35s
>>>.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet
  width: 5px;
  height: 5px;
  margin 0 3px
  display none
  &.swiper-pagination-bullet-active
    background #177FFF
    width: 8px;
    height: 8px;
.__swiper-silde
  padding 12px
  background: linear-gradient(90deg, #FFFFFF 22%, rgba(255,255,255,0.6) 93%);
  box-shadow: 0px 4px 40px 0px rgba(0,0,0,0.05);
  border-radius: 4px 4px 4px 4px;
  height 3.58rem
  margin-top .26rem
  .__image
    width 100%
  .__icon
    width .88rem
  .__text-warp
    padding 0 3px
  .__title
    height: 18px;
    font-size: 14px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    line-height: 16px;
    margin-top 4px
  .__desc-text
    font-size: .14rem;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #818493;
    line-height: .24rem;
    margin-top 8px
.__section-3
  min-height 100vh
  background-image url('~@/assets/images/1.10.0/bg3.png')
  background-size 100% auto
  background-repeat no-repeat
  .__slove
    position relative
    margin .12rem .16rem 0 .16rem
    .__slove-item
      background: linear-gradient(90deg, #FFFFFF 22%, rgba(255,255,255,0.6) 93%);
      box-shadow: 0px 4px 40px 0px rgba(0,0,0,0.05);
      border-radius: 4px 4px 4px 4px;
      margin-bottom .12rem
      overflow hidden
      .__bg
        padding .16rem
        background-size cover
      .__slove-header
        display flex
        align-items center
        .__icon
          width .2rem
          height .2rem
        .__slove-title
          font-size: .15rem;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          line-height: .18rem;
          margin-left .08rem
      .__slove-desc
        height: .24rem;
        font-size: .12rem;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: .24rem;
        letter-spacing: 1px;
        margin-top .02rem
</style>
