import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueClipboard from 'vue-clipboard2'
import VideoController from '@/utils/VideoController'
import {
  Button,
  Form,
  Field,
  Picker,
  Popup,
  Notify,
  Icon,
  Collapse,
  CollapseItem,
  RadioGroup,
  Radio,
  DatetimePicker,
  Uploader
} from 'vant';
import 'vant/lib/index.css';
import vueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.min.css'
import VConsole from "vconsole";
import 'video.js/dist/video-js.min.css'
// new VConsole()
Vue.config.productionTip = false;
Vue.prototype.$player = new VideoController()
Vue.use(VueClipboard)
Vue.use(Button);
Vue.use(Form);
Vue.use(Field);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Notify);
Vue.use(Icon);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(DatetimePicker)
Vue.use(Uploader)
Vue.use(vueAwesomeSwiper)
Vue.config.ignoredElements = ['wx-open-launch-app', 'wx-open-launch-weapp'];
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");