export default class VideoController {
  constructor(){
    this.players = []
  }
  playVideo(video){
    this.players.forEach((item, index) => {
        if(item.uuid !== video.uuid){
          item.player.pause()
        }
    });
  }
  push(video){
    this.players.push(video)
  }
}