/* eslint-disable */
<template>
  <div class="markdown-warp">
    <vue-markdown v-if="loaded" ref="markdown" class="ss-markdown" :source="content">
    </vue-markdown>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'
import axios from 'axios'
// import hljs from 'highlight.js'
export default {
  name: 'docmentCompound',
  components: {
    VueMarkdown
  },
  mounted() {
    
  },
  data() {
    return {
      content: '',
      loaded: false
    }
  },
  methods: {
    _initDoc(doc) {
      this.loaded = false
      axios.request({
        baseURL: '/',
        url: doc + `?t=${Date.now()}`,
        method: 'get',
        headers: {
          // contentType: 'application/x-genesis-rom;charset=utf-8'
          'Content-Type': 'application/json'
        }
      }).then(res => {
        console.log(res.data)
        this.loaded = true
        this.content = res.data + ''
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
.markdown-warp
  padding-top 40px
  min-height 1200px
  width 100%
  padding-bottom 100px
  .ss-markdown
    font-size 16px
    font-weight 400
    color #333333
    line-height 1.5
    >>>h1
      font-size 32px
      font-weight 600
      color #1C2026
      line-height 62px
      padding 30px 0
      border-bottom 1px solid #DFDFDF
    >>>h2
      font-size 20px
      font-weight 600
      color #1C2026
      line-height 40px
      padding 10px 0
    >>>h3
      font-size 16px
      font-weight 600
      color #1C2026
      line-height 40px
      padding 10px 0 10px 0
    >>>h4
      padding 30px 0 10px 0
    >>>p
      line-height 1.5
    >>>p+p
      padding-top 20px
    >>>p+ul
      padding-top 8px
    >>>ul
      margin-left 24px
      li
        padding 3px 0
      li::marker
        color #1C2026
        font-size 1.2em
    >>>ol
      margin-left 24px
      li::marker
        color #1C2026
        font-size 1.2em
      li
        padding 5px 0
    >>>.table
      border-collapse collapse
      width 100%
      th, td
        border 1px solid #e1e1e1
        min-width 100px
        text-align left
        padding 10px
        word-break break-all
      th
        background-color #f5f7f9
    >>>pre
      code
        font-family Consolas, Monaco, monospace
    >>>* + .table
      margin 30px 0
html.h5 
  .markdown-warp
    padding-top .8rem
    min-height 1200px
    width 100%
    padding-bottom .6rem
    .ss-markdown
      font-size .14rem
      font-weight 400
      color #333333
      line-height 28px
      >>>h1
        font-size .3rem
        font-weight 600
        color #1C2026
        line-height 1
        padding .2rem 0
        border-bottom 1px solid #DFDFDF
      >>>h2
        font-size .2rem
        font-weight 600
        color #1C2026
        line-height 1
        padding .25rem 0 .15rem 0
      >>>h3
        font-size .16rem
        font-weight 600
        color #1C2026
        line-height 40px
        padding 10px 0 10px 0
      >>>h4
        font-size .14rem
        padding .15rem 0 .05rem 0
      >>>p
        line-height 1.8
        font-size .14rem
      >>>p+p
        padding-top 20px
      >>>p+ul
        padding-top 8px
      >>>ul
        margin-left 24px
        li
          font-size .14rem
          padding 5px 0
          line-height 1.8
        li::marker
          color #1C2026
          font-size 1.2em
      >>>ol
        margin-left 24px
        li::marker
          color #1C2026
          font-size 0.14rem
        li
          padding 5px 0
          font-size 0.14rem
      >>>.table
        border-collapse collapse
        width 100%
        th, td
          font-size .12rem
          border 1px solid #e1e1e1
          min-width 0
          text-align left
          padding 0 .05rem
          word-break break-all
          a 
            font-size .12rem
        th
          background-color #f5f7f9
      >>>pre
        code
          font-size .12rem
          font-family Consolas, Monaco, monospace
          span 
            font-size .12rem
      >>>* + .table
        margin .2px 0
      >>>strong 
        em
          font-size .12rem
</style>
