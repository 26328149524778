<template>
  <div class="__coop-panel">
    <!-- 近期合作 -->
    <div class="__coop-header">
      <div class="__text">近期合作</div>
    </div>
    <div
      class="__panel"
      :class="{ __animation: isAnimate }"
      :style="{ marginLeft: `${marginLeft / 100}rem`, paddingLeft: `${paddingLeft / 100}rem` }"
    >
      <div class="__coop-warp" ref="coopContainer">
        <template>
          <template v-for="item in allBoxs">
            <CoopBox v-if="item.index % 2 == 0" :key="item.name" :item="item" />
          </template>
        </template>
        <template>
          <template v-for="item in allBoxs">
            <CoopBox v-if="item.index % 2 == 0" :key="item.name + 'copy'" :item="item" />
          </template>
        </template>
      </div>

      <div class="__coop-warp">
        <template>
          <template v-for="item in allBoxs">
            <CoopBox v-if="item.index % 2 == 1" :key="item.name" :item="item" />
          </template>
        </template>
        <template>
          <template v-for="item in allBoxs">
            <CoopBox v-if="item.index % 2 == 1" :key="item.name + 'copy'" :item="item" />
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import CoopBox from './CoopBox.vue';
const files = require.context('@/assets/images/1.10.0/cooper', false, /\.png$/);
export default {
  components: {
    CoopBox,
  },
  data() {
    return {
      allBoxs: [],
      marginLeft: 0,
      isAnimate: true,
      paddingLeft: 0,
      count: 0
    };
  },
  mounted() {
    files.keys().forEach((item, index) => {
      this.allBoxs.push({
        name: item,
        image: files(item),
        index,
      });
    });
    this.$nextTick(() => {
      const width = this.$refs.coopContainer.clientWidth;
    });
    setInterval(() => {
      this.marginLeft = this.marginLeft - 10;
      if (this.marginLeft < (-1309 * (this.count + 1) + 375)) {
        if(this.count === 0){
          this.paddingLeft += (1309 - 375 - 105 + 7)
        }else {
          this.paddingLeft += 1309
        }
        this.count++
      }
    }, 100);
  },
  methods: {},
};
</script>

<style scoped lang="stylus">
.__coop-header 
  display flex 
  justify-content center
  margin-top .16rem
  .__text 
    height: .18rem;
    font-size: .14rem;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #003AD6;
    line-height: .16rem;
    position relative
    &:before 
      content ''
      position absolute
      width: 4px;
      height: 4px;
      background: #177FFF;
      top .07rem  
      left -0.12rem
      border-radius 50%
    &:after 
      content ''
      position absolute
      width: 4px;
      height: 4px;
      background: #177FFF;
      top .07rem 
      right -0.12rem
      border-radius 50%
.__coop-panel
  position relative
  width 100%
  height 2.32rem
  overflow hidden
  background-image url('@/assets/images/1.10.0/coop-bg.png')
  background-size 100% auto 
  background-position bottom
  margin-top .25rem
  .__panel
    position absolute
    margin-top .24rem
    &.__animation
      transition margin linear 1s
  .__coop-warp
    width 26.18rem
</style>
