<template>
  <div class="__cooperation-container">
    <div class="__banner-warp">
      <img
        class="__banner-image"
        src="~@/assets/images/cooperation_banner.png"
        alt=""
      />
      <div class="__banner-text">
        <div class="__banner-text-1">支持SaaS/API/OEM/渠道入驻</div>
        <div class="__banner-text-2">提交后将由专人一对一跟进对接</div>
      </div>
    </div>
    <div class="__content">
      <van-form @submit="onSubmit">
        <van-field
          v-model="formData.name"
          type="text"
          name="您的称呼"
          label=""
          placeholder="您的称呼"
          :maxlength="20"
          :rules="[{ required: true, message: '请填写您的称呼' }]"
        />
        <van-field
          v-model="formData.mobile_number"
          type="tel"
          name="联系手机"
          label=""
          placeholder="联系手机"
          :maxlength="11"
          :rules="[{ required: true, message: '请填写联系手机' }]"
        />
        <van-field
          v-model="formData.company"
          type="text"
          name="公司名称"
          label=""
          placeholder="公司名称"
          :maxlength="30"
          :rules="[{ required: true, message: '请填写公司名称' }]"
        />
        <van-field
          v-model="formData.message"
          type="textarea"
          name="您可补充更多信息，加速合作进程"
          label=""
          placeholder="您可补充更多信息，加速合作进程"
          :maxlength="300"
          show-word-limit
        />
        <van-button class="__submit-btn">立即提交</van-button>
      </van-form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {getRightContact,sendEmail} from "@/api/business.js"
export default {
  data() {
    return {
      formData: {
        name: '',
        mobile_number: '',
        company: '',
        message: '',
      },
      contactData:[]
    };
  },
  mounted() {
    this.getContact()
  },
  methods: {
    getContact(){
        getRightContact().then(res => {
            this.contactData = res.data.data
        })
    },
    onSubmit() {
      let num = Math.floor(Math.random() * this.contactData.contactList.length)

      let mail = this.contactData.contactList[num].mail
      let spareMail = this.contactData.contactList[num].spareMail
      
      sendEmail({
          "productName":this.contactData.productName,
          'mail':mail,
          'spareMail':spareMail,
          // 'mail':'2504423788@qq.com',
          // 'spareMail':'liweipeng@deepsound.cn',
          'realName':this.formData.name,
          'mobilePhone':this.formData.mobile_number,
          'company':this.formData.company
      }).then(res => {
          this.$router.push('/cooperation_result')
      })





      // axios.request({
      //   url: '/v1.0/virtual-human/contact',
      //   method: 'post',
      //   data: this.formData,
      // }).then(res => {
      //   this.$router.push('/cooperation_result')
      // })
    },
  },
};
</script>

<style scoped lang="stylus">
>>>.van-cell 
  &:after 
    display none
.__cooperation-container
    width 100%
    height 100vh
    background: #E8F2FF;
    .__banner-warp
      position relative
      width 100%
      height: 3.17rem;
      background: linear-gradient(360deg, rgba(232,242,255,0) 0%, #E8F2FF 100%)
      .__banner-image
        width 100%
      .__banner-text
        position absolute
        top: .44rem
        left .3rem
        .__banner-text-1
          height: .24rem;
          font-size: .17rem;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #177FFF;
          line-height: .20rem;
        .__banner-text-2
          height: .20rem;
          font-size: .14rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #177FFF;
          line-height: .16rem;
    .__content
      background-color #fff
      border-radius: 8px 8px 8px 8px;
      overflow hidden
      position absolute
      width calc(100% - 0.32rem)
      left .16rem
      top 1.22rem
    .van-form
        padding .24rem .24rem 1.6rem .24rem
        background-color #fff
        .van-field
            line-height .28rem
            background: #F6F8FA;
            border-radius: 4px 4px 4px 4px
            padding .1rem .16rem
            margin-bottom .16rem
        .__submit-btn
            width 100%
            background-color #177fff
            border none
            color #fff
</style>
<style lang="stylus">
.van-field__control
    &::placeholder
        color #8D99AC !important
.van-field__word-limit
  font-size .12rem
  span
    font-size .12rem
</style>
