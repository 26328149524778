import Vue from "vue";
import Vuex from "vuex";
import * as api from '@/api'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isOEM: false,
    brand: {},
    act: false
  },
  getters: {
    isOEM(state){
      const isOEM = sessionStorage.getItem('isOEM')
      if(isOEM === 'false'){
        return false
      }
      return isOEM || state.isOEM
    },
    brand(state){
      // return state.brand || {}
      let brand = sessionStorage.getItem('brand')
      try {
        brand = JSON.parse(brand)
      } catch (error) {
        brand = {}
      } 
      return state.brand || brand || {}
    },
    act(state){
      const act = sessionStorage.getItem('act')
      if(act === 'false'){
        return false
      }
      return act || state.act
    }
  },
  mutations: {
    setOEM(state, isOEM){
      state.isOEM = isOEM 
      sessionStorage.setItem('isOEM', isOEM)
    },
    setBrand(state, brand){
      sessionStorage.setItem('brand', JSON.stringify(brand))
      state.brand = brand
      Vue.set(state, 'brand', brand)
    },
    setAct(state, act){
      state.act = act 
      sessionStorage.setItem('act', act)
    }
  },
  actions: {
    getBrand({commit}){
      api.brandLogo().then((res) => {
        const data = res.data.data;
        if (data) {
          const brand = data.brand;
          commit('setBrand', brand)
        }
      });
    }
  },
  modules: {},
});
