<template>
  <div class="__cooperation-result">
    <div class="__text-1">提交成功</div>
    <div class="__text-2">您可添加商务微信或拨打电话加速进程</div>
    <!-- <img class="__ewm" src="~@/assets/images/1.10.0/code.png" alt="" /> -->
    <img class="__ewm" :src="wechatQrCode" alt="" /> 
    <van-button class="__back-btn" @click="makePhoneCall">电话联系</van-button>
  </div>
</template>

<script>
import { makePhoneCall } from '@/utils'
import {getRightContact} from "@/api/business.js"
export default {
  data() {
    return {
      makePhoneCall,
      contactData:[],
      wechatQrCode:""
    };
  },
  mounted(){
    this.getContact()
  },
  methods:{
    getContact(){
        getRightContact().then(res => {
            this.contactData = res.data.data
            let num = Math.floor(Math.random() * this.contactData.contactList.length)
            this.wechatQrCode = this.contactData.contactList[num].wechatQrCode
        })
    },
  }
};
</script>

<style scoped lang="stylus">
.__cooperation-result
  min-height 100vh;
  padding-top 1.4rem
  display flex 
  flex-direction column 
  align-items center
  .__text-1
    height: .22rem;
    font-size: .20rem;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333333;
    line-height: .22rem;
  .__text-2 
    height: 22px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #666666;
    line-height: 22px;
    margin-top .12rem
  .__ewm 
    width 1.48rem 
    height 1.48rem
    margin-top .24rem
  .__back-btn 
    background-color #177fff
    color white
    border-color #177fff
    width calc(100% - .62rem)
    height .46rem
    margin-top .38rem
</style>
