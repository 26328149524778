var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"__section-1"},[(!_vm.isOEM)?_c('img',{staticClass:"__logo",attrs:{"src":require("@/assets/images/1.10.0/logo.png"),"alt":""}}):_vm._e(),_c('h2',{staticClass:"__title-text-1"},[_vm._v("用数字人做视频")]),_vm._m(0),_c('img',{staticClass:"__lili",attrs:{"src":require("@/assets/images/1.10.0/lili.png"),"alt":""}}),(!_vm.isOEM)?_c('div',{staticClass:"__tip-container"},[_vm._m(1),_vm._m(2)]):_c('div',{staticClass:"__tip-container"},[_vm._m(3),_vm._m(4)]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"__title-text-2"},[_c('span',[_vm._v("分分钟")]),_c('span',{staticClass:"__blue"},[_vm._v("干完一天的活")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"__tip-box",staticStyle:{"margin-top":"0"}},[_c('div',{staticClass:"__tip-box-text"},[_vm._v(" 小米投资，中科院博士带队攻关，掌握全栈技术 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"__tip-box",staticStyle:{"margin-top":"0.12rem"}},[_c('div',{staticClass:"__tip-box-text"},[_vm._v(" 深受"),_c('span',{staticStyle:{"color":"#fe5913"}},[_vm._v("小米、OPPO、中科院、奥美广告")]),_vm._v("等客户信赖 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"__tip-box",staticStyle:{"margin-top":"0"}},[_c('div',{staticClass:"__tip-box-text"},[_vm._v(" 录制"),_c('span',{staticStyle:{"color":"#fe5913"}},[_vm._v("8min视频")]),_vm._v("，即可"),_c('span',{staticStyle:{"color":"#fe5913"}},[_vm._v("1:1复刻")]),_vm._v("真人，终身使用 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"__tip-box",staticStyle:{"margin-top":"0.12rem"}},[_c('div',{staticClass:"__tip-box-text"},[_vm._v(" 输入文字或音频，数字人即可说话，神态"),_c('span',{staticStyle:{"color":"#fe5913"}},[_vm._v("堪比真人")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"__info-box"},[_c('div',{staticClass:"__box-item"},[_c('div',{staticClass:"__text-1"},[_vm._v("95+")]),_c('div',{staticClass:"__text-2"},[_vm._v("数字人还原度，业界领先")])]),_c('div',{staticClass:"__box-item"},[_c('div',{staticClass:"__text-1"},[_vm._v("20,000,000+")]),_c('div',{staticClass:"__text-2"},[_vm._v("累计用户")])]),_c('div',{staticClass:"__box-item"},[_c('div',{staticClass:"__text-1"},[_vm._v("SaaS/API/OEM")]),_c('div',{staticClass:"__text-2"},[_vm._v("支持多种合作方式")])])])
}]

export { render, staticRenderFns }